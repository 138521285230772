@if (compareValues){
<div class="compare-values flexbox h-100-percent align-center justify-center w-100-percent">
  <div class="left-value">
    @if (compareValues.leftValue) {
      <div class="value accent">
        {{ (compareValues.leftValue.value || 0) | germanNumber:'1.0-2' }}
      </div>
      <div class="sub-text">
        {{ compareValues.leftValue.subText }}
      </div>
    }
  </div>
  <div class="v-divider accent-background-color"></div>
  <div class="right-value">
    @if (compareValues.rightValue) {
      <div class="value accent">
        {{ (compareValues.rightValue.value || 0) | germanNumber:'1.0-2' }}
      </div>
      <div class="sub-text">
        {{ compareValues.rightValue.subText }}
      </div>
    }
  </div>
</div>
}
