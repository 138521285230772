import { UsageProfileEnum } from '../app/enums/UsageProfile.enum';
import { IndicatorStatusEnum } from '../app/enums/IndicatorStatus.enum';
import { DgnbSubjectEnum } from '../app/enums/DgnbSubject.enum';
import { UsageProfileTypeEnum } from '../app/enums/UsageProfileTypeEnum';
import { DisciplineEnum } from '../app/enums/Discipline.enum';

/**
 * Retrieves the key of an enum by its value.
 *
 * This function takes an enum object and a value, and returns the key associated with that value.
 * If the value is not found in the enum, it returns undefined.
 *
 * @template T - The type of the enum object.
 * @param {T} enumObj - The enum object to search within.
 * @param {string} value - The value to find the corresponding key for.
 * @returns {keyof T | undefined} The key associated with the given value, or undefined if not found.
 */
export function getEnumKeyByValue<T extends { [key: string]: string }>(
  enumObj: T,
  value: string
): keyof T | undefined {
  return (Object.keys(enumObj) as Array<keyof T>).find(
    (key) => enumObj[key] === value
  );
}

export function getDgnbSubjectEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  if (!(key in DgnbSubjectEnum)) return '';
  return DgnbSubjectEnum[key.toUpperCase() as keyof typeof DgnbSubjectEnum];
}

export function getUsageProfileEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  if (!(key in UsageProfileEnum)) return '';

  return UsageProfileEnum[key as keyof typeof UsageProfileEnum];
}

export function getUsageProfileTypeEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  if (!(key in UsageProfileTypeEnum)) return '';
  return UsageProfileTypeEnum[key as keyof typeof UsageProfileTypeEnum];
}

export function getIndicatorStatusEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  if (!(key in IndicatorStatusEnum)) return '';
  return IndicatorStatusEnum[key as keyof typeof IndicatorStatusEnum];
}

export function getDisciplineEnumValue(key: string | null | undefined): string {
  if (!key) return '';
  if (!(key in DisciplineEnum)) return '';
  return DisciplineEnum[key as keyof typeof DisciplineEnum];
}
