import * as am5 from '@amcharts/amcharts5';

/**
 * Converts an array of hex color strings to an array of amCharts Color objects.
 *
 * @param {string[]} colors - An array of hex color strings.
 * @returns {am5.Color[]} An array of amCharts Color objects.
 */
export function getAmColorsFromHexStrings(colors: string[]): am5.Color[] {
  return colors?.map(color => am5.color(color));
}
