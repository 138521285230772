/**
 * Sorts array by key
 *
 * @param array
 * @param key
 * @param ascending
 * @returns
 */
export function sortByKey<T = unknown>(array: T[], key: string, ascending: boolean = true): T[] {
  const collator = new Intl.Collator(undefined, {
    numeric: array.length > 0 && typeof array[0][key as keyof T] === 'number',
    sensitivity: 'base',
  });

  return array.sort((b, a) => {
    return ascending
      ? collator.compare((<unknown>b[key as keyof T]) as string, (<unknown>a[key as keyof T]) as string)
      : collator.compare((<unknown>a[key as keyof T]) as string, (<unknown>b[key as keyof T]) as string);
  });
}

/**
 * Removes duplicates from an array of objects based on a specified key.
 * @template T - The type of the array elements.
 * @param {T[]} array - The input array.
 * @param {keyof T} key - The key based on which duplicates are identified.
 * @returns {T[]} An array containing only unique objects based on the specified key.
 */
export function removeDuplicates<T>(array: T[], key: keyof T): T[] {
  const uniqueSet = new Set(array.map(item => item[key]));
  return Array.from(uniqueSet).map((keyValue: unknown) => array.find(item => item[key] === keyValue) as T);
}

/**
 * Transforms an array of integers or numeric strings into a distinct and complete array.
 * This function removes any duplicates and fills in missing integers between the smallest
 * and largest integers in the original array. If any string cannot be parsed into an integer,
 * an error is thrown.
 *
 * @param {(number | string)[]} numbers - The array of integers or strings to process.
 * @returns {number[]} A new array that is sorted, contains no duplicates, and includes
 *                     every integer between the minimum and maximum.
 * @throws {Error} Throws an error if any string in the array cannot be parsed as an integer.
 */
export function makeArrayDistinctAndComplete(numbers: (number | string)[]): number[] {
  const parsedNumbers: number[] = numbers.map(item => {
    const parsed = parseInt(item.toString(), 10);
    if (isNaN(parsed)) {
      throw new Error(`Invalid input: "${item}" is not a parseable number.`);
    }
    return parsed;
  });

  // Remove duplicates by converting the array to a Set and then back to an array
  const uniqueNumbers = Array.from(new Set(parsedNumbers));

  // Sort the unique numbers to facilitate the filling process
  uniqueNumbers.sort((a, b) => a - b);

  // Determine the minimum and maximum values from the sorted array
  const min = uniqueNumbers[0];
  const max = uniqueNumbers[uniqueNumbers.length - 1];

  // Create a complete array that includes all integers from min to max
  const completeArray = [];
  for (let i = min; i <= max; i++) {
    completeArray.push(i);
  }

  return completeArray;
}

/**
 * Filters a map by keeping only the specified keys.
 *
 * This function iterates over the entries of the provided map and removes any entries
 * whose keys are not included in the `keysToKeep` array.
 *
 * @template K - The type of the keys in the map.
 * @template V - The type of the values in the map.
 * @param {Map<K, V>} map - The map to be filtered.
 * @param {K[]} keysToKeep - An array of keys that should be retained in the map.
 */
export function createFilteredMap<K, V>(map: Map<K, V>, keysToKeep: K[]): Map<K, V> {
  const newMap = new Map<K, V>();

  keysToKeep.forEach(key => {
    if (map.has(key)) {
      newMap.set(key, map.get(key)!);
    }
  });

  return newMap;
}

/**
 * Shuffles an array of strings in place.
 *
 * This function creates a copy of the input array to avoid mutating the original array.
 * It then uses the Fisher-Yates algorithm to shuffle the elements of the array.
 *
 * @param {string[]} array - The array of strings to shuffle.
 * @returns {string[]} A new array with the elements shuffled.
 */
export function shuffleArray(array: string[]): string[] {
  const shuffledArray = [...array]; // Create a copy to avoid mutating the original array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
  }
  return shuffledArray;
}

/**
 * Removes every nth entry from an array.
 * @param arr
 * @param n
 */
export function removeEveryNthEntry<T = unknown>(arr: T[], n: number = 2): T[] {
  if (n <= 0) {
    throw new Error("Parameter n must be greater than 0");
  }
  return arr?.filter((_, index) => (index + 1) % n !== 0);
}
