import * as am5 from '@amcharts/amcharts5';
import { DARK_HIGHLIGHTS, DARK_THEME, DEFAULT_HIGHLIGHTS, DEFAULT_THEME } from './material-colors';
import { Color } from '@amcharts/amcharts5';

export const euleDefaultChartColors: Color[] = [
  am5.color(DEFAULT_THEME.accent),
  am5.color(DEFAULT_HIGHLIGHTS.secondary),
  am5.color(DEFAULT_HIGHLIGHTS.accent3),
  am5.color(DEFAULT_HIGHLIGHTS.info),
  am5.color(DARK_HIGHLIGHTS.accent2)
];

export const euleDarkChartColors: Color[] = [
  am5.color(DARK_THEME.accent),
  am5.color(DARK_THEME.primary),
  am5.color(DARK_HIGHLIGHTS.accent3),
  am5.color(DARK_HIGHLIGHTS.info),
  am5.color(DARK_HIGHLIGHTS.accent2)
];
