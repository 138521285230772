import { Injectable } from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DgnbCriteriaGroup,
  DgnbIndicatorCatalogue,
  PreCheckIndicator,
  PreCheckScenario,
  Project,
  ProjectUser,
  UsageProfile,
  User,
} from '@eeule/eeule-shared';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  getDocs,
  limit,
  onSnapshot,
  Query,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import {
  BehaviorSubject,
  catchError,
  filter,
  from,
  lastValueFrom,
  map,
  Observable,
  of,
  ReplaySubject,
  Subscriber,
  Subscription,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { DgnbSubjectEnum } from '../../enums/DgnbSubject.enum';
import { UsageProfileEnum } from '../../enums/UsageProfile.enum';
import { MatSelectOption, ScenarioCollectionType } from '../../types/common-types';
import { CollectionQueryResponse, DocumentQueryResponse, FirebaseDocumentData, OrderByCondition, QueryCondition } from '../../types/firebase-types';
import { FirebaseService } from './firebase.service';
import { SnackbarService } from './snackbar.service';
import { ProjectUserDisplay, UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public projectUser$: BehaviorSubject<ProjectUser | null> = new BehaviorSubject<ProjectUser | null>(null);
  public usageProfiles$: BehaviorSubject<Array<UsageProfile>> = new BehaviorSubject<Array<UsageProfile>>([]);
  public projectUsers$: BehaviorSubject<ProjectUserDisplay[]> = new BehaviorSubject<ProjectUserDisplay[]>([]);
  private _projectUsersSubscription: Subscription | undefined;
  public project$: BehaviorSubject<Project | null> = new BehaviorSubject<Project | null>(null);
  private _projectSubscription: Subscription | undefined;
  private selectedPreCheckScenarioIdSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  readonly selectedPreCheckScenarioId$: Observable<string | null> = this.selectedPreCheckScenarioIdSubject.asObservable();
  private selectedAuditScenarioIdSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  readonly selectedAuditScenarioId$: Observable<string | null> = this.selectedAuditScenarioIdSubject.asObservable();

  private unsubscribeProjectSnapshot: (() => void) | null = null;
  private unsubscribeUsersSnapshot: (() => void) | null = null;
  private unsubscribeUsersDisplaySnapshot: (() => void) | null = null;

  constructor(
    private _firebaseService: FirebaseService,
    private _snackBarService: SnackbarService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService
  ) {
    this._loadUsageProfiles();
  }

  public initProjectListeners(projectId: string) {
    this._listenToProject(projectId);
  }

  public cleanUp() {
    this.project$.next(null);
    this.projectUser$.next(null);
    this.projectUsers$.next([]);
    this.usageProfiles$.next([]);
    this.usageProfiles$.next([]);

    if (this.unsubscribeProjectSnapshot) {
      this.unsubscribeProjectSnapshot();
      this.unsubscribeProjectSnapshot = null;
    }
    if (this.unsubscribeUsersSnapshot) {
      this.unsubscribeUsersSnapshot();
      this.unsubscribeUsersSnapshot = null;
    }
    if (this.unsubscribeUsersDisplaySnapshot) {
      this.unsubscribeUsersDisplaySnapshot();
      this.unsubscribeUsersDisplaySnapshot = null;
    }
  }

  /** --- PROJECT --- **/
  // CLOUD FUNCTIONS
  public callCreateProject(newProjectDialogResult: Project): Observable<HttpsCallableResult<string>> {
    const userId: string = getAuth().currentUser!.uid;
    const _callCreateProject = httpsCallable<Project, string>(this._firebaseService.functions, 'callCreateProject');
    return from(_callCreateProject({ ...newProjectDialogResult, projectOwner: userId }));
  }

  // CLIENT FUNCTIONS
  public createProject(project: Project): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${project.id}`);
    return from(setDoc(docRef, project));
  }

  public getProjectById(projectId: string) {
    const docRef = doc(this._firebaseService.firestore, `projects`, projectId);
    return from(getDoc(docRef));
  }

  public getProjectByIdLive(projectId: string): Observable<Project> {
    this._projectSubscription?.unsubscribe();
    this.projectUser$.next(null); // reset ProjectUser to null since project has been switched
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}`);

    return new Observable(observer => {
      this.unsubscribeProjectSnapshot = onSnapshot(
        docRef,
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => observer.next(snapshot.data() as Project),
        (error: FirestoreError) => observer.error(error.message)
      );

      return {
        unsubscribe: () => {
          if (this.unsubscribeProjectSnapshot) {
            this.unsubscribeProjectSnapshot();
            this.unsubscribeProjectSnapshot = null;
          }
        },
      };
    });
  }

  public getProjectsByIds(projectIds: string[]): Observable<Project[]> {
    if (!projectIds.length) throw new Error(`Project IDs Array needs to have at least one element. A non-empty array is required for ' in ' filters.`);
    const _projectsRef: CollectionReference<DocumentData, DocumentData> = collection(this._firebaseService.firestore, `projects`);
    const _query: Query<unknown, DocumentData> = query(_projectsRef, where('id', 'in', projectIds));
    return from(getDocs(_query)).pipe(
      map((projects: QuerySnapshot<unknown, DocumentData>) => projects.docs.map((doc: QueryDocumentSnapshot<unknown, DocumentData>) => doc.data() as Project)),
      catchError(error => {
        console.error('Error fetching projects:', error);
        return of([]); // Gibt eine leere Liste im Fehlerfall zurück
      })
    );
  }

  public getProjectsByIdsLive(projectIds: string[]): Observable<Project[]> {
    if (!projectIds.length) throw new Error(`Project IDs Array needs to have at least one element. A non-empty array is required for ' in ' filters.`);
    const _projectsRef: CollectionReference<DocumentData, DocumentData> = collection(this._firebaseService.firestore, `projects`);
    const _query: Query<unknown, DocumentData> = query(_projectsRef, where('id', 'in', projectIds));
    return new Observable((observer: Subscriber<Project[]>) => {
      return onSnapshot(
        _query,
        (snapshot: QuerySnapshot<unknown, DocumentData>) => observer.next(snapshot.docs.map(projectSnap => projectSnap.data() as Project)),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public updateProject(projectId: string, projectData: Project) {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}`);
    return from(updateDoc(docRef, { ...projectData }));
  }

  private _listenToProject(projectId: string) {
    // reset old observable and subscription
    this._projectSubscription?.unsubscribe();
    this.project$.next(null);
    this._projectSubscription = this.getProjectByIdLive(projectId)
      .pipe(
        tap(project => {
          this.project$.next(project);
        })
      )
      .subscribe(() => {
        this._getAndSetLiveAllProjectUsers(); // FIXME: id might not be set
        this._setCurrentProjectUser();
      });
  }

  /** --- USAGE PROFILES --- */
  public getAllUsageProfiles(): Observable<UsageProfile[]> {
    const colRef = collection(this._firebaseService.firestore, `usageProfiles`);
    return from(getDocs(colRef))
      .pipe(map(profile => profile.docs))
      .pipe(map(profiles => profiles.map(profile => profile.data() as UsageProfile)));
  }

  public getUsageProfileName(profileId: string): Observable<UsageProfileEnum> {
    return this.usageProfiles$.pipe(
      map((profiles: UsageProfile[]) => UsageProfileEnum[profiles.find(prof => prof.id === profileId)!.name]),
      take(1)
    );
  }

  private _loadUsageProfiles(): void {
    this._userService.euleUser$
      .pipe(
        filter(user => !!user),
        switchMap(() => this.getAllUsageProfiles())
      )
      .subscribe(_profiles => this.usageProfiles$.next(_profiles));
  }

  /** ------ */

  /** --- PROJECT USERS --- */
  public addUserToProject(projectId: string, authUserId: string, projectuserId?: string): Observable<void> {
    if (!projectId) return throwError(() => 'No ProjectID');
    if (!authUserId) return throwError(() => 'No UserID');
    const user: ProjectUser = {
      id: projectuserId || crypto.randomUUID(),
      authUserId: authUserId,
      roles: [],
      license: false,
    };
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/users/${user.id}`);
    return from(setDoc(docRef, user));
  }

  /**
   * Deletes the referenced AuthUserId from the Project. Only the ProjectUserID remains to keep project internal references alive.
   *
   * @param {string} projectId
   * @param {ProjectUser} user
   * @returns {Observable<void>}
   *
   * @memberOf ProjectService
   */
  public deleteUserFromProject(projectId: string, user: ProjectUser): Observable<void> {
    if (!projectId) return throwError(() => 'No ProjectID');
    if (!user.id) return throwError(() => 'No UserID');

    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/users/${user.id}`);
    return from(updateDoc(docRef, { authUserId: null })).pipe(switchMap(() => this._userService.deleteProjectFromUser(projectId, user.authUserId)));
  }

  public inviteUserByEmail(userEmail: string) {
    const usersColRef: CollectionReference<DocumentData, DocumentData> = collection(this._firebaseService.firestore, `users`);
    const _query: Query<DocumentData, DocumentData> = query(usersColRef, where('email', '==', userEmail));

    return from(getDocs(_query)).pipe(
      tap((users: QuerySnapshot<DocumentData, DocumentData>) => {
        if (!users.docs.length) {
          throw new Error('No Users found');
        }
        if (users.docs.length > 1) {
          return throwError(() => 'Multiple Users matching the email found');
        } else {
          return users.docs[0];
        }
      }),
      map((users: QuerySnapshot<DocumentData, DocumentData>) => {
        return users.docs[0].data() as User;
      }),
      tap((userSnap: User) => {
        if (userSnap.projectIds?.includes(this.project$.value!.id)) {
          throw new Error('User exists already in Project');
        }
      }),
      switchMap((user: User) => this.addUserToProject(this.project$.value!.id!, user.id).pipe(map(() => user))),
      switchMap((user: User) => this._userService.addProjectToUser(this.project$.value!.id!, user.id))
    );
  }

  public _future_inviteUserToProject(projectId: string, userId: string): Observable<DocumentReference<DocumentData, DocumentData>> {
    const docRef = collection(this._firebaseService.firestore, `projects/${projectId}/invitations`);
    const user: ProjectUser = { id: crypto.randomUUID(), authUserId: userId, roles: [], license: false };
    return from(addDoc(docRef, user));
  }

  /**
   * Retrieves all users for a given project from Firestore.
   *
   * @param {string} projectId - The ID of the project.
   * @returns {Observable<ProjectUser[]>} An observable emitting an array of ProjectUser objects.
   */
  public getProjectUsers(projectId: string): Observable<ProjectUser[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/users`);
    return from(getDocs(colRef)).pipe(
      map(usersDocs => usersDocs.docs),
      map(usersSnap => usersSnap.map(userSnap => userSnap.data() as ProjectUser))
    );
  }

  public getLiveProjectUsers(projectId: string): Observable<ProjectUser[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/users`);

    return new Observable(observer => {
      this.unsubscribeUsersSnapshot = onSnapshot(
        colRef,
        (usersArraySnapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(usersArraySnapshot.docs.map((userSnap: QueryDocumentSnapshot<DocumentData, DocumentData>) => userSnap.data() as ProjectUser)),
        (error: FirestoreError) => observer.error(error.message)
      );

      // Optional: Cleanup logic if the observer unsubscribes from the Observable
      return {
        unsubscribe: () => {
          if (this.unsubscribeUsersSnapshot) {
            this.unsubscribeUsersSnapshot();
            this.unsubscribeUsersSnapshot = null;
          }
        },
      };
    });
  }

  public getLiveProjectUserDisplay(projectId: string, projectUserId: string): Observable<ProjectUserDisplay | undefined> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/users`);

    return new Observable(observer => {
      return onSnapshot(
        colRef,
        (usersArraySnapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(usersArraySnapshot.docs.map((userSnap: QueryDocumentSnapshot<DocumentData, DocumentData>) => userSnap.data() as ProjectUser)),
        (error: FirestoreError) => observer.error(error.message)
      );
    }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switchMap((users: ProjectUser[]) => this._userService.mapAuthUsersDataToProjectUsers(users)) as ProjectUserDisplay[] | any,
      map((users: ProjectUserDisplay[]) => users.find((usr: ProjectUser) => usr.id === projectUserId))
    );
  }

  public getProjectUserDisplay(projectId: string, projectUserId: string): Observable<ProjectUserDisplay> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/users`);

    return from(getDocs(colRef)).pipe(
      map(usersDocs => usersDocs.docs),
      map(usersSnap => usersSnap.map(userSnap => userSnap.data() as ProjectUser)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switchMap((users: ProjectUser[]) => this._userService.mapAuthUsersDataToProjectUsers(users)) as ProjectUserDisplay[] | any,
      map((users: ProjectUserDisplay[]) => users.find((usr: ProjectUser) => usr.id === projectUserId) as ProjectUserDisplay)
    );
  }

  public getLiveProjectUsersDisplay(projectId: string): Observable<ProjectUserDisplay[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/users`);

    return new Observable<ProjectUser[]>(observer => {
      this.unsubscribeUsersDisplaySnapshot = onSnapshot(
        colRef,
        (usersArraySnapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(usersArraySnapshot.docs.map((userSnap: QueryDocumentSnapshot<DocumentData, DocumentData>) => userSnap.data() as ProjectUser)),
        (error: FirestoreError) => observer.error(error.message)
      );

      // Optional: Cleanup logic if the observer unsubscribes from the Observable
      return {
        unsubscribe: () => {
          if (this.unsubscribeUsersDisplaySnapshot) {
            this.unsubscribeUsersDisplaySnapshot();
            this.unsubscribeUsersDisplaySnapshot = null;
          }
        },
      };
    }).pipe(switchMap((users: ProjectUser[]) => this._userService.mapAuthUsersDataToProjectUsers(users))) as Observable<ProjectUserDisplay[]>;
  }

  public _getAndSetLiveAllProjectUsers() {
    this._projectUsersSubscription?.unsubscribe();

    this.getLiveProjectUsersDisplay(this.project$.value!.id)
      .pipe(
        tap((usersdocs: ProjectUserDisplay[]) => {
          this.projectUsers$.next(usersdocs);
        })
      )
      .subscribe();
  }

  public async cloudFunctionSoftDeleteProject(projectId: string): Promise<void> {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callSoftDeleteProject';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
    const requestData = {
      projectId: projectId,
    };

    const user = getAuth().currentUser;
    if (!user) {
      throw new Error('no User');
    }

    const idToken = await user.getIdToken(); // Token für Authentifizierung abrufen

    return (
      fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`, // Authentifizierungstoken hinzufügen
        },
        body: JSON.stringify(requestData),
      })
        // .then(response => response.json())
        .then(data => {
          console.log('Response:', data);
        })
        .catch(error => {
          console.error('Error:', error);
        })
    );
  }

  private _setCurrentProjectUser() {
    this.project$
      .pipe(
        switchMap((project: Project | null) => {
          if (!project) return of(null);
          const _projectUsersRef: CollectionReference<DocumentData, DocumentData> = collection(this._firebaseService.firestore, `projects/${project.id}/users`);
          const _query: Query<unknown, DocumentData> = query(_projectUsersRef, where('authUserId', '==', this._userService.euleUser$.value!.id), limit(1));
          return from(getDocs(_query)).pipe(map((querySnap: QuerySnapshot<unknown, DocumentData>) => querySnap.docs[0].data() as ProjectUser));
        }),
        map((projectUser: ProjectUser | null) => this.projectUser$.next(projectUser))
      )
      .subscribe();
  }

  /** ------ */

  /** --- SCENARIOS --- */
  public getScenario(scenarioId: string, collectionType: ScenarioCollectionType = 'preCheckScenarios'): Observable<DocumentQueryResponse<PreCheckScenario>> {
    const projectId: string | null | undefined = this.project$.value?.id;
    if (!projectId) {
      throw new Error('an error occurred while retrieving the project');
    }
    const path: string = `projects/${projectId}/${collectionType}`;
    return this._firebaseService.getDocumentData<PreCheckScenario>(path, scenarioId, true);
  }

  /**
   * Fetches all pre-check scenarios for the current project.
   * @returns {Observable<CollectionQueryResponse<PreCheckScenario>>} An observable containing the collection of pre-check scenarios.
   */
  public getScenarios(
    projectId: string,
    collectionType: ScenarioCollectionType = 'preCheckScenarios'
  ): Observable<CollectionQueryResponse<PreCheckScenario>> {

    // Construct the path to the collection of pre-check scenarios for the current project
    const path: string = `projects/${projectId}/${collectionType}`;

    // Retrieve the collection data from Firebase
    return this._firebaseService.getCollectionData<PreCheckScenario>(path, null, null, true);
  }

  /**
   * Retrieves scenarios by their IDs from Firestore.
   * @param {string[]} scenarioIds - The array of scenario IDs to retrieve.
   * @param {ScenarioCollectionType} [collectionType='preCheckScenarios'] - The type of scenario collection to retrieve.
   * @param {boolean} [filterDeletedScenarios='true'] - if true, scenarios with prop deleted: true will not be included in the result.
   * @returns {Observable<QuerySnapshot<unknown, DocumentData>>} An observable that emits the query snapshot of the scenarios.
   * @throws Will throw an error if the scenarioIds array is empty.
   */
  public getScenariosByIds(
    scenarioIds: string[],
    collectionType: ScenarioCollectionType = 'preCheckScenarios',
    filterDeletedScenarios: boolean = true
  ): Observable<PreCheckScenario[]> {
    if (!scenarioIds.length) {
      throw new Error(`Scenario IDs Array needs to have at least one element. A non-empty array is required for 'in' filters.`);
    }

    const projectId = this.project$.value?.id;
    if (!projectId) {
      throw new Error('Project ID is not defined');
    }

    // Construct the reference to the collection
    const _scenariosRef: CollectionReference<DocumentData, DocumentData> = collection(
      this._firebaseService.firestore,
      `projects/${projectId}/${collectionType}`
    );

    // Execute the query and return an observable of the query snapshot
    return from(getDocs(_scenariosRef)).pipe(
      map(scenarioSnaps =>
        scenarioSnaps.docs
          .map(scenarioSnap => {
            const scenario: PreCheckScenario = { ...(scenarioSnap.data() as PreCheckScenario), id: scenarioSnap.id };
            return scenario;
          })
          .filter(scenario => {
            if (filterDeletedScenarios) {
              return scenarioIds.includes(scenario.id) && !scenario.deleted;
            }
            return scenarioIds.includes(scenario.id);
          })
      )
    );
  }

  /**
   * Updates a specific scenario (either pre-check or audit) with new data.
   *
   * @param {'preCheck' | 'audit'} type - The type of scenario to update (either 'preCheck' or 'audit').
   * @param {string} scenarioId - The ID of the scenario to update.
   * @param {Partial<PreCheckIndicator>} data - The partial data to update the scenario with.
   * @returns {Observable<void>} An observable that completes when the update operation is done.
   */
  public updateScenario(type: 'preCheck' | 'audit', scenarioId: string, data: Partial<PreCheckScenario>): Observable<void> {
    // Construct the path to the DGNB indicators collection within the specified catalogue
    const path: string = type === 'preCheck' ? `projects/${this.project$.value!.id}/preCheckScenarios` : `projects/${this.project$.value!.id}/auditScenarios`;

    // Create a reference to the document
    const docRef: DocumentReference = doc(this._firebaseService.firestore, path, scenarioId);

    // Update the document with new data and timestamp
    return from(
      updateDoc(docRef, {
        ...data,
        updateTime: serverTimestamp(),
      })
    );
  }

  /**
   * Deletes a specific scenario (either pre-check or audit).
   *
   * @param {'preCheck' | 'audit'} type - The type of scenario to update (either 'preCheck' or 'audit').
   * @param {string} scenarioId - The ID of the scenario to update.
   * @returns {Observable<void>} An observable that completes when the update operation is done.
   */
  public deleteScenario(type: 'preCheck' | 'audit', scenarioId: string): Observable<void> {
    // Construct the path to the DGNB indicators collection within the specified catalogue
    const path: string = type === 'preCheck' ? `projects/${this.project$.value!.id}/preCheckScenarios` : `projects/${this.project$.value!.id}/auditScenarios`;

    // Create a reference to the document
    const docRef: DocumentReference = doc(this._firebaseService.firestore, path, scenarioId);

    // Delete the document
    return from(deleteDoc(docRef));
  }

  /**
   * Sets the provided id as globally selected scenario id (pre-check, audit)
   * @param id
   * @param type
   */
  public setSelectedScenarioId(id: string, type: 'preCheck' | 'audit' = 'preCheck') {
    if (type === 'preCheck' && id === this.selectedPreCheckScenarioIdSubject.value) return;
    if (type === 'audit' && id === this.selectedAuditScenarioIdSubject.value) return;
    if (type === 'preCheck') this.selectedPreCheckScenarioIdSubject.next(id);
    if (type === 'audit') this.selectedAuditScenarioIdSubject.next(id);
  }

  public getLiveScenario(projectId: string, scenarioPath: string): Observable<PreCheckScenario> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/${scenarioPath}`);

    return new Observable(observer => {
      return onSnapshot(
        docRef,
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) =>
          observer.next({
            ...snapshot.data(),
            id: snapshot.id,
          } as PreCheckScenario),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  /**
   * Retrieves live scenarios from Firestore in real-time updates.
   * @param {string} projectId - The ID of the project.
   * @param {ScenarioCollectionType} [collectionType='preCheckScenarios'] - The type of scenario collection to retrieve.
   * @returns {Observable<PreCheckScenario[]>} An observable that emits live updates of scenarios.
   */
  public getLiveScenarios(projectId: string, collectionType: ScenarioCollectionType = 'preCheckScenarios'): Observable<PreCheckScenario[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/${collectionType}`);

    return new Observable(observer => {
      return onSnapshot(
        colRef,
        (snapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(
            snapshot.docs
              .map(
                doc =>
                  ({
                    ...doc.data(),
                    id: doc.id,
                  } as PreCheckScenario)
              )
              .filter(scenarios => !scenarios.deleted) // ignore deleted scenarios
          ),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  /**
   *
   *
   * @param {string} projectId
   * @param {string} scenarioPath for example `preCheckScenarios/<ID-of-preCheckScenario>` or `auditScenarios/<ID-of-auditScenario>`
   * @returns {Observable<ProjectUser[]>}
   *
   * @memberOf ProjectService
   */
  public getLiveScenarioProgress(projectId: string, scenarioPath: string): Observable<number> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/${scenarioPath}`);

    return new Observable(observer => {
      return onSnapshot(
        docRef,
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => observer.next((snapshot.data() as PreCheckScenario)?.cloudProgress),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  /**
   * Retrieves all pre-check scenarios and maps them to an array of PreCheckScenario objects.
   * Sets selected scenario id to first found entry, if not already set
   * @param collectionType
   * @returns {Observable<MatSelectOption[]>} An Observable emitting an array of MatSelectOption objects.
   */
  public retrieveScenariosAsMatOptionsAndSelect(collectionType: ScenarioCollectionType = 'preCheckScenarios'): Observable<MatSelectOption[]> {
    try {
      const selectedScenarioId: string | null =
        collectionType === 'preCheckScenarios' ? this.selectedPreCheckScenarioIdSubject.value : this.selectedAuditScenarioIdSubject.value;
      //fixme just a workaround, project should be emitted elsewhere at this point
      let projectId: string | undefined = this.project$.value?.id;
      if (!projectId) {
        const urlProjectPartArr: string[] = this._router.url.split('/');
        const projectIndex: number = urlProjectPartArr.indexOf('project');
        projectId = urlProjectPartArr[projectIndex + 1] || '';
      }
      const path: string = `projects/${projectId}/${collectionType}`;
      return this._firebaseService.getCollectionData<PreCheckScenario>(path, null, null, true).pipe(
        map((scenarios: CollectionQueryResponse<PreCheckScenario>) => {
          return (
            scenarios.data
              ?.filter(
                (scenario: FirebaseDocumentData<PreCheckScenario>) => !scenario.deleted
                // && (!scenario.cloudProgress || scenario.cloudProgress === 100) // TODO: has to be discussed
              )
              .map((scenario: FirebaseDocumentData<PreCheckScenario>) => ({
                value: scenario.id,
                viewValue: scenario.name,
              })) || []
          );
        }),
        tap(async (options: MatSelectOption[]) => {
          let scenarioIdToSelect: string | null = null;
          if (
            options.length &&
            (!selectedScenarioId ||
              !options.some(option => {
                return option.value === selectedScenarioId;
              }))
          ) {
            scenarioIdToSelect = options[0]?.value;
          }
          const scenarioIdFromUrlParams = this._route.snapshot.queryParamMap.get('scenarioId');
          if (!scenarioIdFromUrlParams && scenarioIdToSelect) {
            this.setSelectedScenarioId(scenarioIdToSelect, collectionType === 'preCheckScenarios' ? 'preCheck' : 'audit');
            return;
          }
          const scenarioResponse: DocumentQueryResponse<PreCheckScenario> | '' | null =
            scenarioIdFromUrlParams && (await lastValueFrom(this.getScenario(scenarioIdFromUrlParams, collectionType)));
          if ((scenarioResponse as DocumentQueryResponse<PreCheckScenario>)?.data?.id) {
            scenarioIdToSelect = scenarioIdFromUrlParams;
          }
          if (scenarioIdToSelect) {
            this.setSelectedScenarioId(scenarioIdToSelect, collectionType === 'preCheckScenarios' ? 'preCheck' : 'audit');
          }
        })
      );
    } catch (error) {
      this._snackBarService.showErrorMessage('Es ist ein Fehler aufgetreten.');
      throw new Error('error');
    }
  }

  /** ------ */

  /** --- SCENARIO CRITERIA GROUPS --- */
  public getAllScenarioCriteriaGroups(scenarioId: string, dgnbSubject: DgnbSubjectEnum, collectionType: ScenarioCollectionType = 'preCheckScenarios') {
    const colRef = collection(this._firebaseService.firestore, `projects/${this.project$.value!.id}/${collectionType}/${scenarioId}/${dgnbSubject}`);
    return from(getDocs(colRef)).pipe(
      map(criteriaGroups => {
        return criteriaGroups.docs.map(
          criteriaGroupSnap =>
            ({
              ...criteriaGroupSnap.data(),
              id: criteriaGroupSnap.id,
            } as DgnbCriteriaGroup)
        );
      })
    );
  }

  public getScenarioCriteriaGroup(
    scenarioId: string,
    subject: string,
    groupId: string,
    collectionType: ScenarioCollectionType = 'preCheckScenarios'
  ): Observable<DocumentQueryResponse<DgnbCriteriaGroup>> {
    const projectId: string | null | undefined = this.project$.value?.id;
    if (!projectId) {
      this._snackBarService.showErrorMessage('Fehler beim Abrufen des Projektes');
      throw new Error('an error occurred while retrieving the project');
    }
    const path: string = `projects/${projectId}/${collectionType}/${scenarioId}/${subject}`;
    return this._firebaseService.getDocumentData<DgnbCriteriaGroup>(path, groupId);
  }

  /** ------ */

  /** --- SCENARIO INDICATOR CATALOGUES --- */
  public getAllScenarioIndicatorCatalogues(
    scenarioId: string,
    dgnbSubject: DgnbSubjectEnum,
    groupId: string,
    collectionType: ScenarioCollectionType = 'preCheckScenarios'
  ) {
    const colRef = collection(
      this._firebaseService.firestore,
      `projects/${this.project$.value!.id}/${collectionType}/${scenarioId}/${dgnbSubject}/${groupId}/catalogues`
    );
    return from(getDocs(colRef)).pipe(
      map(indicatorCatalogues => {
        return indicatorCatalogues.docs.map(
          catalogueSnap =>
            ({
              ...catalogueSnap.data(),
              id: catalogueSnap.id,
            } as DgnbIndicatorCatalogue)
        );
      })
    );
  }

  public getScenarioIndicatorCatalogue(
    scenarioId: string,
    subject: string,
    groupId: string,
    catalogueId: string,
    collectionType: ScenarioCollectionType = 'preCheckScenarios'
  ): Observable<DocumentQueryResponse<DgnbIndicatorCatalogue>> {
    const projectId: string | null | undefined = this.project$.value?.id;
    if (!projectId) {
      this._snackBarService.showErrorMessage('Fehler beim Abrufen des Projektes');
      throw new Error('an error occurred while retrieving the project');
    }
    const path: string = `projects/${projectId}/${collectionType}/${scenarioId}/${subject}/${groupId}/catalogues`;
    return this._firebaseService.getDocumentData<DgnbIndicatorCatalogue>(path, catalogueId);
  }

  /** ------ */

  /** --- INDICATORS --- */
  getAllScenarioIndicators(
    scenarioId: string,
    dgnbSubject: DgnbSubjectEnum,
    criteriaGroupId: string,
    catalogueId: string,
    collectionType: ScenarioCollectionType = 'preCheckScenarios',
    queryConditions?: QueryCondition[] | null,
    orderByCondition?: OrderByCondition | null
  ): Observable<CollectionQueryResponse<PreCheckIndicator>> {
    // Construct the path to the collection of DGNB indicators within the specified catalogue
    const path: string = `projects/${
      this.project$.value!.id
    }/${collectionType}/${scenarioId}/${dgnbSubject}/${criteriaGroupId}/catalogues/${catalogueId}/indicators`;

    // Determine orderBy conditions
    const orderByConditions: OrderByCondition[] | null = orderByCondition ? [orderByCondition] : null;

    // Retrieve the collection of DGNB indicators
    return this._firebaseService.getCollectionData<PreCheckIndicator>(path, queryConditions, orderByConditions);
  }

  /**
   * Retrieves live updates of all scenario indicators from Firestore.
   *
   * This function constructs the path to the collection of DGNB indicators within the specified catalogue
   * and sets up a listener for real-time updates. It returns an observable that emits the updated collection data.
   *
   * @param {string} scenarioId - The ID of the scenario.
   * @param {DgnbSubjectEnum} dgnbSubject - The subject of the DGNB.
   * @param {string} criteriaGroupId - The ID of the criteria group.
   * @param {string} catalogueId - The ID of the catalogue.
   * @param {ScenarioCollectionType} [collectionType='preCheckScenarios'] - The type of scenario collection.
   * @param {ReplaySubject<boolean>} [stop$] - The observable that signals when to unsubscribe.
   * @param {QueryCondition[] | null} [queryConditions] - Optional query conditions to filter the collection.
   * @param {OrderByCondition | null} [orderByCondition] - Optional order by condition to sort the collection.
   * @param {(keyof PreCheckIndicator)[]} [trackedProperties] - Optional list of properties to track for changes.
   * @returns {Observable<PreCheckIndicator[]>} An observable that emits the updated collection data.
   */
  getLiveAllScenarioIndicators(
    scenarioId: string,
    dgnbSubject: DgnbSubjectEnum,
    criteriaGroupId: string,
    catalogueId: string,
    collectionType: ScenarioCollectionType = 'preCheckScenarios',
    stop$?: ReplaySubject<boolean>, // The observable that signals when to unsubscribe
    queryConditions?: QueryCondition[] | null,
    orderByCondition?: OrderByCondition | null,
    trackedProperties?: (keyof PreCheckIndicator)[]
  ): Observable<PreCheckIndicator[]> {
    // Construct the path to the collection of DGNB indicators within the specified catalogue
    const path = `projects/${this.project$.value!.id}/${collectionType}/${scenarioId}/${dgnbSubject}/${criteriaGroupId}/catalogues/${catalogueId}/indicators`;

    return this._firebaseService.listenToCollectionChangesOnSnapshot<PreCheckIndicator>(
      path,
      queryConditions,
      orderByCondition,
      trackedProperties,
      true,
      stop$
    );
  }

  getScenarioIndicator(
    scenarioId: string,
    dgnbSubject: DgnbSubjectEnum,
    criteriaGroupId: string,
    catalogueId: string,
    indicatorId: string,
    collectionType: ScenarioCollectionType = 'preCheckScenarios',
    skipIsFetching?: boolean
  ): Observable<DocumentQueryResponse<PreCheckIndicator>> {
    const path: string = `projects/${
      this.project$.value!.id
    }/${collectionType}/${scenarioId}/${dgnbSubject}/${criteriaGroupId}/catalogues/${catalogueId}/indicators`;
    return this._firebaseService.getDocumentData<PreCheckIndicator>(path, indicatorId, skipIsFetching);
  }

  /**
   * Updates a specific indicator within a pre-check scenario in Firestore.
   *
   * @param {string} scenarioId - The ID of the pre-check scenario.
   * @param {DgnbSubjectEnum} dgnbSubject - The subject of the DGNB system.
   * @param {string} criteriaGroupId - The ID of the criteria group.
   * @param {string} catalogueId - The ID of the indicator catalogue.
   * @param {string} indicatorId - The ID of the indicator to update.
   * @param {Partial<PreCheckIndicator>} data - The partial data to update the indicator with.
   * @param {Partial<ScenarioCollectionType>} collectionType - the type of the scenario collection as path fragment
   * @returns {Observable<void>} An Observable that completes when the update operation is done.
   */
  public updatePreCheckScenarioIndicator(
    scenarioId: string,
    dgnbSubject: DgnbSubjectEnum,
    criteriaGroupId: string,
    catalogueId: string,
    indicatorId: string,
    data: Partial<PreCheckIndicator>,
    collectionType: ScenarioCollectionType = 'preCheckScenarios'
  ): Observable<void> {
    // Construct the path to the DGNB indicators collection within the specified catalogue
    const path: string = `projects/${
      this.project$.value!.id
    }/${collectionType}/${scenarioId}/${dgnbSubject}/${criteriaGroupId}/catalogues/${catalogueId}/indicators`;

    // Create a reference to the document
    const docRef: DocumentReference = doc(this._firebaseService.firestore, path, indicatorId);

    // Update the document with new data and timestamp
    return from(
      updateDoc(docRef, {
        ...data,
        updateTime: serverTimestamp(),
      })
    );
  }

  /** ------ */
}
