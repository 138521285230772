import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentData as EuleDocumentData } from '@eeule/eeule-shared';
import { deleteDoc, doc, DocumentData, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ListResult,
  ref,
  StorageReference,
  uploadBytes,
  UploadResult,
} from 'firebase/storage';
import { BehaviorSubject, catchError, from, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import { FirebaseService } from './firebase.service';

export const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'heif', 'heic'];
export const allowedDocumentExtensions = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'heif', 'heic', 'xlsx', 'xls'];
export const MAX_FILE_SIZE_IMAGES = 8 * 1024 * 1024; // 8MB
export const MAX_FILE_SIZE_DOCUMENTS = 50 * 1024 * 1024; // 50MB

export interface EuleDocumentMetadata {}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   * FIXME: use cloud funciton on storage filechanges to write change-metadata into a firestore collection.
   * Listen here to this fileChange collection for live updates
   *
   * @type {BehaviorSubject<Array<string>>}
   * @memberOf StorageService
   */
  public fileChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _firebaseService: FirebaseService, private _http: HttpClient) {}

  public uploadProfilePicture(authUserId: string, blob: Blob): Observable<UploadResult> {
    const storage = getStorage();
    const storageRef = ref(storage, `profilePictures/${authUserId}`);
    // 'file' comes from the Blob or File API
    return from(uploadBytes(storageRef, blob));
  }

  public async downloadProfilePicture(authUserId: string): Promise<string | null> {
    const storage = getStorage();
    const storageRef = ref(storage, `profilePictures/${authUserId}`);

    try {
      return await getDownloadURL(storageRef);
    } catch (error) {
      return null;
    }
  }

  /**
   * Uploads a image to the storage. Path is completely individual choice, starting from root level.
   * TODO: make sure only image types are uploaded
   *
   * @param {string} path
   * @param {Blob} blob
   * @returns {Observable<UploadResult>}
   *
   * @memberOf StorageService
   */
  public uploadImage(path: string, blob: Blob): Observable<UploadResult> {
    const storage = getStorage();
    const storageRef = ref(storage, path);

    // 'file' comes from the Blob or File API
    return from(uploadBytes(storageRef, blob));
  }

  /**
   * Downloads any document from given path.
   * TODO: make sure only images are returned
   *
   * @param {string} path
   * @returns {Observable<string>}
   *
   * @memberOf StorageService
   */
  public downloadImage(path: string): Observable<string> {
    const storage = getStorage();
    const storageRef = ref(storage, path);

    // 'file' comes from the Blob or File API
    return from(getDownloadURL(storageRef));
  }

  /**
   * Uploads a document to storage and immedeately sets this document also in firestore.
   * Bot paths are identical `projects/${projectId}/documents/${documentData.id}`
   *
   * @param {string} projectId
   * @param {EuleDocumentData} documentData
   * @param {EuleDocumentMetadata} [metaData]
   * @returns
   *
   * @memberOf StorageService
   */
  public uploadProjectDocument(projectId: string, documentData: EuleDocumentData, metaData?: EuleDocumentMetadata) {
    const storage = getStorage();
    const path: string = `projects/${projectId}/documents/${documentData.id}`;
    const storageRef = ref(storage, path);
    const docRef = doc(this._firebaseService.firestore, path);

    return from(uploadBytes(storageRef, documentData.data, metaData)).pipe(switchMap(() => setDoc(docRef, { ...documentData, data: null })));
  }

  public getProjectDocumentMetadata(projectId: string, documentId: string) {
    const storage = getStorage();
    const storageRef = ref(storage, `projects/${projectId}/documents/${documentId}`);

    return from(getMetadata(storageRef));
  }

  /**
   * Retrieves a downloadable URL from a firebase storage document
   *
   * @param {string} projectId
   * @param {string} documentId
   * @returns {Observable<string>}
   *
   * @memberOf StorageService
   */
  public getProjectDocumentDownloadUrl(projectId: string, documentId: string): Observable<string> {
    const storage = getStorage();
    const storageRef = ref(storage, `projects/${projectId}/documents/${documentId}`);

    return from(getDownloadURL(storageRef));
  }

  /**
   * Downloads a (firebase storage-) URL to the local file system
   *
   * @param {string} url
   * @param {string} [name]
   *
   * @memberOf StorageService
   */
  public downloadFileByUrlToSystem(url: string, name?: string) {
    this._http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a'); // invisible element
      link.href = blobUrl; // add data to element
      if (name) {
        link.download = name; // add name for object
      }
      document.body.appendChild(link); // add element to DOM
      link.click(); // click invisible element
      document.body.removeChild(link); // clean up DOM
      window.URL.revokeObjectURL(blobUrl); // clean up window
    });
  }

  public getProjectDocumentData(projectId: string, documentId: string): Observable<EuleDocumentData> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/documents/${documentId}`);
    return from(getDoc(docRef)).pipe(map((snap: DocumentSnapshot<DocumentData, DocumentData>) => snap.data() as EuleDocumentData));
  }

  /**
   * Deletes a document from the Firestore project/documents collection and the File Storage.
   * Does NOT delete the document references from tasks and indicators etc.
   *
   * @param {string} projectId
   * @param {string} documentId
   *
   * @memberOf StorageService
   */
  public deleteDocumentFromProject(projectId: string, documentId: string) {
    const storage = getStorage();
    const path: string = `projects/${projectId}/documents/${documentId}`;
    const storageRef = ref(storage, path);
    const docRef = doc(this._firebaseService.firestore, path);

    return from(deleteObject(storageRef)).pipe(
      catchError(err => of(err)),
      switchMap(() => deleteDoc(docRef))
    );
  }

  public getAllProjectDocumentsFromStorage(projectId: string): Observable<ListResult> {
    const listRef = ref(getStorage(), `projects/${projectId}/documents`);
    return from(listAll(listRef));
  }

  public propagateFileChanges() {
    this.fileChanges$.next(true);
  }

  public deleteDocument(path: string): Observable<void> {
    // Create a reference to the file to delete
    const desertRef = ref(getStorage(), path);

    // Delete the file
    return from(deleteObject(desertRef));
  }

  public isImageExtensionAllowed(file: File): boolean {
    if (!file) return false;
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension) return false;
    if (!allowedImageExtensions.includes(fileExtension)) {
      return false;
    }
    return true;
  }

  public isImageFileSizeAllowed(file: File): boolean {
    if (!file) return false;
    if (file.size > MAX_FILE_SIZE_IMAGES) {
      return false;
    }
    return true;
  }

  public isImageUploadAllowed(file: File): boolean {
    return this.isImageExtensionAllowed(file) && this.isImageFileSizeAllowed(file);
  }

  public isDocumentExtensionAllowed(file: File): boolean {
    if (!file) return false;
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension) return false;
    if (!allowedDocumentExtensions.includes(fileExtension)) {
      return false;
    }
    return true;
  }

  public isDocumentFileSizeAllowed(file: File): boolean {
    if (!file) return false;
    if (file.size > MAX_FILE_SIZE_DOCUMENTS) {
      return false;
    }
    return true;
  }

  public isDocumentUploadAllowed(file: File): boolean {
    return this.isDocumentExtensionAllowed(file) && this.isDocumentFileSizeAllowed(file);
  }

  public calculateFolderSize(folderPath: string): Observable<number> {
    const storage = getStorage();
    const folderRef: StorageReference = ref(storage, folderPath);

    return this._calculateSizeRecursively(folderRef);
  }

  private _calculateSizeRecursively(folderRef: StorageReference): Observable<number> {
    return from(listAll(folderRef)).pipe(
      mergeMap(result => {
        // Array von Promises, um Metadaten für jede Datei abzurufen
        const fileMetadataPromises = result.items.map(itemRef => getMetadata(itemRef));

        // Berechnen der Größe der aktuellen Dateien
        return from(Promise.all(fileMetadataPromises)).pipe(
          mergeMap(metadataList => {
            // Gesamtgröße der Dateien in diesem "Ordner"
            const totalSize = metadataList.reduce((acc, metadata) => acc + (metadata.size || 0), 0);

            // Verarbeitung aller Unterordner rekursiv
            const folderSizeObservables = result.prefixes.map(subFolderRef => this._calculateSizeRecursively(subFolderRef));

            // Summiere die Größen der Dateien und die Größen der Unterordner
            return folderSizeObservables.length > 0
              ? of(totalSize).pipe(
                  mergeMap(size =>
                    folderSizeObservables.reduce(
                      (acc, obs) => acc.pipe(mergeMap((total: number) => obs.pipe(mergeMap(subSize => of(total + subSize))))),
                      of(size)
                    )
                  )
                )
              : of(totalSize);
          }),
          catchError(error => {
            console.error('Fehler beim Abrufen der Metadaten:', error);
            return of(0); // Rückgabe von 0 im Fehlerfall, um die Berechnung fortzusetzen
          })
        );
      })
    );
  }
}
