import { Injectable } from '@angular/core';
import { ProjectUser, User } from '@eeule/eeule-shared';
import { getAuth } from 'firebase/auth';
import {
  CollectionReference,
  DocumentData,
  DocumentSnapshot,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Unsubscribe,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { BehaviorSubject, Observable, from, map, switchMap, take, throwError } from 'rxjs';
import { FirebaseService } from './firebase.service';

/**
 * Only for display purposes used in User List
 *
 * @export
 * @interface ProjectUserDisplay
 * @extends {ProjectUser}
 */
export interface ProjectUserDisplay extends ProjectUser {
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  phone?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /**
   * The Eule User with AuthUser ID (not the projectUser with projectUserID)
   *
   * @type {(BehaviorSubject<User | null>)}
   * @memberOf UserService
   */
  public euleUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  private _unsub: Unsubscribe | null = null;

  public constructor(private _firebaseService: FirebaseService) {}

  public init() {
    this._unsub = onSnapshot(
      doc(this._firebaseService.firestore, `users/`, getAuth().currentUser?.uid || '_'),
      (doc: DocumentSnapshot<DocumentData, DocumentData>) => {
        const _user: User = doc.data() as User;
        this.euleUser$.next(_user);
      }
    );
  }

  cleanUp() {
    if (this._unsub) {
      this._unsub();
    }
    this.euleUser$.next(null);
  }

  public getUser(userId: string): Observable<User> {
    const docRef = doc(this._firebaseService.firestore, `users/`, userId);
    return from(getDoc(docRef)).pipe(map((userSnap: DocumentSnapshot<DocumentData, DocumentData>) => userSnap.data() as User));
  }

  public setUser(user: User) {
    const docRef = doc(this._firebaseService.firestore, `users/`, user.id);
    return from(setDoc(docRef, user));
  }

  public updateUser(userId: string, data: Partial<User>) {
    const docRef = doc(this._firebaseService.firestore, `users/`, userId);
    return from(updateDoc(docRef, data));
  }

  public getUserName(userId: string) {
    return this.getUser(userId).pipe(
      map((user: User) => {
        return `${user.firstName} ${user.lastName}`;
      }),
      take(1)
    );
  }

  public getAuthUsersByIds(authUsersIds: string[]): Observable<User[]> {
    const usersColRef: CollectionReference<DocumentData, DocumentData> = collection(this._firebaseService.firestore, `users`);
    const _query: Query<DocumentData, DocumentData> = query(usersColRef, where('id', 'in', authUsersIds));

    return from(getDocs(_query)).pipe(
      map((usersSnap: QuerySnapshot<DocumentData, DocumentData>) =>
        usersSnap.docs.map((user: QueryDocumentSnapshot<DocumentData, DocumentData>) => user.data() as User)
      )
    );
  }

  public mapAuthUsersDataToProjectUsers(users: ProjectUser[]): Observable<ProjectUserDisplay[]> {
    return this.getAuthUsersByIds(users.map((user: ProjectUser) => user.authUserId)).pipe(
      map((authUsers: User[]) =>
        authUsers.map((euleUser: User) => {
          const mappedProjectUserDisplay: ProjectUser = users.find((user: ProjectUser) => user.authUserId === euleUser.id)!;
          return {
            ...mappedProjectUserDisplay,
            title: euleUser.title,
            firstName: euleUser.firstName,
            lastName: euleUser.lastName,
            email: euleUser.email,
            company: euleUser.company,
            phone: euleUser.phone,
          } as ProjectUserDisplay;
        })
      )
    );
  }

  public addProjectToUser(projectId: string, userId: string) {
    const userDocRef = doc(this._firebaseService.firestore, `users/${userId}`);
    return from(
      getDoc(userDocRef).then((userSnap: DocumentSnapshot<DocumentData, DocumentData>) => {
        const user: User | undefined = userSnap.data() as User;
        const _oldProjectIds = user.projectIds || [];
        user.projectIds = [..._oldProjectIds, projectId];

        // Refer new Project in Creating Users ProjectIds

        return setDoc(userDocRef, user)
          .then(() => {})
          .catch((error: Error) => {
            console.error('ERROR in addProjectToUser()', error);
          });
      })
    );
  }

  public deleteProjectFromUser(projectId: string, authUserId: string) {
    if (!projectId) return throwError(() => 'No ProjectID');
    if (!authUserId) return throwError(() => 'No UserID');

    return this.getUser(authUserId).pipe(
      switchMap((user: User) => {
        const indexOfProjectId = user.projectIds!.indexOf(projectId);
        user.projectIds!.splice(indexOfProjectId, 1);
        return this.updateUser(user.id, { projectIds: user.projectIds });
      })
    );
  }
}
