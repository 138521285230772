import {Component, Input} from '@angular/core';
import {NgTemplateOutlet} from "@angular/common";
import { ToolbarSetDirective } from '../../directives/toolbar-set.directive';

export type ToolbarSet = 'reduced' | 'basic' | 'extended' | 'noColorsWithSuperScript' | 'empty' | 'custom';

export type ToolbarSetConfig = {
  hasStrokedText?: boolean;
  hasQuote?: boolean;
  hasCodeBlock?: boolean;
  hasSuperScript?: boolean;
  hasHeadingShortcuts?: boolean;
  hasHeadingPicker?: boolean;
  hasColorPicker?: boolean;
  hasImagePicker?: boolean;
}

@Component({
  selector: 'eule-custom-quill-editor-toolbar-set',
  standalone: true,
  templateUrl: './custom-quill-editor-toolbar-set.component.html',
  imports: [
    ToolbarSetDirective,
    NgTemplateOutlet
  ],
  styleUrl: './custom-quill-editor-toolbar-set.component.scss'
})
export class CustomQuillEditorToolbarSetComponent {
  @Input() toolbarSet: ToolbarSet = 'basic';
  @Input() toolbarConfig?: ToolbarSetConfig
  @Input() allConfigPropsActivated?: boolean = false;
  @Input() allowImages: boolean = true;
  @Input() allowColorPicker: boolean = true;
}
