import { Timestamp } from 'firebase/firestore';

export const dateStrings = {
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    shortMonths: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    shortDays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    goToToday: "Heute"
  };

/**
 * Returns german date string by given date
 * @param dateOrTime - date to format
 * @param useShortString - determines if short string should be used (dd.MM.yyyy)
 */
export function getLocaleDateString(dateOrTime: Date | number, useShortString?: boolean): string | null {
  const date = typeof dateOrTime === 'number'
    ? new Date(dateOrTime)
    : dateOrTime;
  if (!date || !isValidDate(date)) {
    console.error("the provided date object is not a valid date");
    return null;
  }
  const tmpDay = date.getDay();
  const tmpMonth = date.getMonth();
  const day = dateStrings.days[tmpDay];
  const month = dateStrings.months[tmpMonth];
  if (useShortString) {
    const adjustedMonth: number = date.getMonth() + 1;
    const dayShort = date.getDate() < 10
      ? "0" + date.getDate()
      : date.getDate();
    const monthShort = adjustedMonth < 10
      ? "0" + adjustedMonth
      : adjustedMonth;
    return `${dayShort}.${monthShort}.${date.getFullYear()}`;
  }
  return `${day}, ${date.getDate()}, ${month} 20${date.getFullYear() % 100}`;
}

/**
 * Converts a timestamp, date, string, or number to a JavaScript `Date` object.
 *
 * @param {Timestamp | Date | string | number} [date] - The input date in various possible formats.
 * @returns {Date | null} The converted `Date` object, or `null` if the input is invalid.
 */
export function timeStampToDate(date?: Timestamp | Date | string | number): Date | null {
  if (!date) return null;

  if ((date as Timestamp)?.seconds !== undefined) {
    return (date as Timestamp).toDate();
  }

  if (isValidDate(date)) return date as Date;

  if ((typeof date === 'string' || typeof date === 'number') && isValidDate(new Date(date))) {
    return new Date(date);
  }

  return null;
}

/**
 * Checks if the given input is a valid `Date` object.
 *
 * @param {unknown} date - The input to check.
 * @returns {boolean} `true` if the input is a valid `Date` object, otherwise `false`.
 */
function isValidDate(date: unknown): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}
