import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'germanNumber',
  standalone: true,
})
export class GermanNumberPipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {}

  transform(value: number, format: string = '1.2-2'): string | null {
    return this._decimalPipe.transform(value, format, 'de-DE');
  }
}
