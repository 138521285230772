import { Component, Input } from '@angular/core';
import { GermanNumberPipe } from '../../pipes/german-number.pipe';
import { DecimalPipe } from '@angular/common';

export type CompareValue = {
  value: number;
  subText: string;
}

export type CompareValues = {
  leftValue: CompareValue;
  rightValue: CompareValue;
}

@Component({
  selector: 'eule-compare-values-visualization',
  standalone: true,
  imports: [
    GermanNumberPipe,
  ],
  providers: [DecimalPipe],
  templateUrl: './compare-values-visualization.component.html',
  styleUrl: './compare-values-visualization.component.scss'
})
export class CompareValuesVisualizationComponent {
  // The compare values to visualize (left and right value)
  @Input({required: true}) compareValues!: CompareValues;

}
