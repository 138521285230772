<div [id]="chartId"
     #amChart
     [euleTooltip]="hasTooltip && hasLegend && tooltip ? tooltip : null"
     [htmlTooltipContent]="true"
     [tooltipPosition]="tooltipPosition"
     [tooltipPositionOffsetY]="tooltipPositionOffsetY"
     [tooltipPositionOffsetX]="tooltipPositionOffsetX"
     [style.height.px]="height"
     class="donut-chart"
>
</div>
