import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Theme } from '../../types/theme-types';
import {
  DARK_HIGHLIGHTS, DARK_TEXT_COLORS,
  DARK_THEME,
  DEFAULT_HIGHLIGHTS,
  DEFAULT_TEXT_COLORS,
  DEFAULT_THEME,
} from '../../../constants/material-colors';

export type MaterialThemeColors = {
  primary: string;
  accent: string;
  warn: string;
}

export type HighlightColors = {
  primary: string;
  secondary: string;
  accent: string;
  accent2: string;
  accent3: string;
  accent4: string;
  accent5: string;
  success: string;
  info: string;
  warn: string;
  error: string;
  bronze: string;
  silver: string;
  gold: string;
  platinum: string;
  inactive: string;
  optional: string;
}

export type TextColors = {
  primaryText: string;
  secondaryText: string;
  disabledText: string;
  divider: string;
  secondaryDivider: string;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  /**
   * A writable signal that holds the current theme.
   * Initialized with the default theme.
   *
   * @private
   * @type {WritableSignal<Theme>}
   */
  private theme: WritableSignal<Theme> = signal<Theme>('default-theme');

  /**
   * A readonly signal that exposes the current theme.
   *
   * @readonly
   * @type {Signal<Theme>}
   */
  readonly themeSig: Signal<Theme> = this.theme.asReadonly();

  /**
   * Sets the current theme.
   *
   * @param {Theme} theme - The theme to set.
   */
  setTheme(theme: Theme) {
    this.theme.set(theme);
  }

  /**
   * Retrieves the material theme colors based on the current theme.
   *
   * @returns {MaterialThemeColors} The material theme colors.
   */
  get themeColors(): MaterialThemeColors {
    if (this.themeSig() === 'dark-theme') {
      return DARK_THEME;
    }
    return DEFAULT_THEME;
  }

  /**
   * Retrieves the highlight colors based on the current theme.
   *
   * @returns {HighlightColors} The highlight colors.
   */
  get themeHighlightColors(): HighlightColors {
    if (this.themeSig() === 'dark-theme') {
      return DARK_HIGHLIGHTS;
    }
    return DEFAULT_HIGHLIGHTS;
  }

  /**
   * Retrieves the text colors based on the current theme.
   *
   * @returns {TextColors} The text colors.
   */
  get themeTextColors(): TextColors {
    if (this.themeSig() === 'dark-theme') {
      return DARK_TEXT_COLORS;
    }
    return DEFAULT_TEXT_COLORS;
  }

}
