<ng-template defaultToolbarSet #defaultToolbarSet let-config>
  <span class="ql-formats">
      <button class="ql-bold"> </button>
      <button class="ql-italic"> </button>
      <button class="ql-underline"> </button>
    @if (config?.hasStrokedText) {
      <button class="ql-strike"> </button>
    }
  </span>

  @if (config?.hasQuote || config?.haseCodeBlock) {
    <span class="ql-formats">
      @if (config?.hasQuote) {
        <button class="ql-blockquote"> </button>
      }
      @if (config?.haseCodeBlock) {
        <button class="ql-code-block"> </button>
      }
    </span>
  }

  @if (config?.hasSuperScript) {
    <span class="ql-formats">
          <button class="ql-script" value="super"> </button>
          <button class="ql-script" value="sub"> </button>
      </span>
  }

  @if (config?.hasHeadingShortcuts) {
    <span class="ql-formats">
    <button class="ql-header" value="1"> </button>
    <button class="ql-header" value="2"> </button>
  </span>
  }

  <span class="ql-formats">
      <select class="ql-size">
        <option value="small">Klein</option>
        <option selected></option>
        <option value="large">Größer</option>
        <option value="huge">Groß</option>
      </select>

    @if (config?.hasHeadingPicker) {
      <select class="ql-header">
        <option value="1"></option>
        <option value="2"></option>
        <option value="3"></option>
        <option value="4"></option>
        <option value="5"></option>
        <option value="6"></option>
        <option selected></option>
      </select>
    }
  </span>

  <span class="ql-formats">
      <button class="ql-list" value="ordered"> </button>
      <button class="ql-list" value="bullet"> </button>
      <select class="ql-align">
        <option selected></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
    </span>

  @if (config?.hasColorPicker) {
    <span class="ql-formats">
    <select class="ql-color">
    </select>
    <select class="ql-background">
    </select>
    </span>
  }

  <span class="ql-formats">
      <button class="ql-link"> </button>
    @if (allowImages && config?.hasImagePicker) {
      <button class="ql-image"> </button>
    }
  </span>
</ng-template>

@switch (toolbarSet) {
  @case ('reduced') {
    <ng-container [ngTemplateOutlet]="defaultToolbarSet" [ngTemplateOutletContext]="{$implicit: {
                  hasStrokedText: true,
                  }}" />
    <ng-content />
  }
  @case ('noColorsWithSuperScript') {
    <ng-container [ngTemplateOutlet]="defaultToolbarSet"
                  [ngTemplateOutletContext]="{$implicit: {
                  hasImagePicker: true,
                  hasStrokedText: true,
                  hasSuperScript: true
                  }}" />
    <ng-content />
  }
  @case ('basic') {
    <ng-container [ngTemplateOutlet]="defaultToolbarSet"
                  [ngTemplateOutletContext]="{$implicit: {
                  hasImagePicker: true,
                  hasStrokedText: true,
                  hasColorPicker: true,
                  }}" />
    <ng-content />
  }
  @case ('extended') {
    <ng-container [ngTemplateOutlet]="defaultToolbarSet"
                  [ngTemplateOutletContext]="{$implicit: {
                  hasImagePicker: true,
                  hasStrokedText: true,
                  hasColorPicker: true,
                  hasHeadingPicker: true,
                  hasQuote: true,
                  haseCodeBlock: true,
                  hasHeadingShortcuts: true,
                  hasSuperScript: true
                  }}" />
    <ng-content />
  }
  @case ('custom') {
      <ng-container [ngTemplateOutlet]="defaultToolbarSet"
                    [ngTemplateOutletContext]="{$implicit: {
                      hasImagePicker: toolbarConfig?.hasImagePicker !== undefined
                        ? !!toolbarConfig!.hasImagePicker
                        : !!allConfigPropsActivated,
                      hasStrokedText: toolbarConfig?.hasStrokedText !== undefined
                        ? !!toolbarConfig!.hasStrokedText
                        : !!allConfigPropsActivated,
                      hasColorPicker: toolbarConfig?.hasColorPicker !== undefined
                        ? !!toolbarConfig!.hasColorPicker
                        : !!allConfigPropsActivated,
                      hasHeadingPicker: toolbarConfig?.hasHeadingPicker !== undefined
                        ? !!toolbarConfig!.hasHeadingPicker
                        : !!allConfigPropsActivated,
                      hasQuote: toolbarConfig?.hasQuote !== undefined
                        ? !!toolbarConfig!.hasQuote
                        : !!allConfigPropsActivated,
                      haseCodeBlock: toolbarConfig?.hasCodeBlock !== undefined
                        ? !!toolbarConfig!.hasCodeBlock
                        : !!allConfigPropsActivated,
                      hasHeadingShortcuts: toolbarConfig?.hasHeadingShortcuts !== undefined
                        ? !!toolbarConfig!.hasHeadingShortcuts
                        : !!allConfigPropsActivated,
                      hasSuperScript: toolbarConfig?.hasSuperScript !== undefined
                        ? !!toolbarConfig!.hasSuperScript
                        : !!allConfigPropsActivated,
                  }}" />
    <ng-content />
  }
  @case ('empty') {
    <ng-content />
  }
}
