import { FirebaseError } from 'firebase/app';

/**
 * Handles basic errors by throwing an appropriate error.
 *
 * @param {unknown} [error] - The error to handle. It can be of any type.
 * @throws {Error} Throws an error with an appropriate message.
 */
export function handleBasicError(error?: unknown) {
  if (typeof error === 'string') {
    throw new Error(error);
  }
  if ((error as Error)?.message) {
    throw new Error((error as Error).message);
  }
  if (error) {
    throw (error);
  }

  throw ('an error occurred');
}

/**
 * Retrieves the error message from an unknown error object.
 *
 * This function attempts to extract a meaningful error message from an unknown error object.
 * It handles different types of error objects, including strings, FirebaseError objects,
 * and generic Error objects. If the error object does not match any of these types,
 * it converts the error to a string. If the error object is null or undefined,
 * it returns a default error message.
 *
 * @param {unknown} error - The error object to retrieve the message from.
 * @returns {string} The error message.
 */
export function retrieveErrorMessageFromUnknownError(error: unknown): string {
  // Check if the error is a string and return it directly
  if (typeof error === 'string') {
    return error;
  }

  // Check if the error is a FirebaseError and return its message
  if ('code' in (error as FirebaseError) && 'message' in (error as FirebaseError)) {
    const firebaseError = error as FirebaseError;
    return `${firebaseError.code}: ${firebaseError.message}`;
  }

  // Check if the error is a generic Error object and return its message
  if ((error as Error)?.message) {
    return (error as Error).message;
  }

  // If the error is not a recognized type, convert it to a string
  if (error) {
    return String(error);
  }

  // Return a default error message if the error is null or undefined
  return 'an error occurred';
}
