import {Directive} from "@angular/core";

/**
 * Interface representing the configuration for the ToolbarSet.
 */
export interface ToolbarSetConfig {
  /**
   * Indicates whether the toolbar set has stroked text.
   */
  hasStrokedText?: boolean;

  /**
   * Indicates whether images are allowed in the toolbar set.
   */
  hasImagePicker?: boolean;

  /**
   * Indicates whether the toolbar set has super script.
   */
  hasSuperScript?: boolean;

  /**
   * Indicates whether the toolbar set has color picker.
   */
  hasColorPicker?: boolean;

  /**
   * Indicates whether the toolbar set has header picker.
   */
  hasHeadingPicker?: boolean;

  /**
   * Indicates whether the toolbar offers the option to quote text.
   */
  hasQuote?: boolean;

  /**
   * Indicates whether the toolbar set has a code block.
   */
  haseCodeBlock?: boolean;

  /**
   * Indicates whether the toolbar has shortcut buttons for headings.
   */
  hasHeadingShortcuts?: boolean;

}

/**
 * Interface representing the context for the ToolbarSet.
 */
interface ToolbarSetContext {
  /**
   * The implicit context value, which is a ToolbarSetConfig.
   */
  $implicit: ToolbarSetConfig | undefined;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template[defaultToolbarSet]',
  standalone: true,
})
/**
 * Directive to provide context for the ToolbarSet.
 */
export class ToolbarSetDirective {
  /**
   * Type guard to ensure the context is of type ToolbarSetContext.
   *
   * @param dir - The instance of ToolbarSetDirective.
   * @param ctx - The context to check.
   * @returns True if the context is of type ToolbarSetContext, false otherwise.
   */
  static ngTemplateContextGuard(
    dir: ToolbarSetDirective,
    ctx: ToolbarSetContext
  ): ctx is ToolbarSetContext {
    return !!(ctx && dir);
  }
}
