import { HighlightColor, ScenarioCollectionType } from '../app/types/common-types';
import { DgnbSystem, FulfillmentLevelEntry, SubjectValue } from '@eeule/eeule-shared';
import * as am5 from '@amcharts/amcharts5';
import { ProgressPart } from '../app/core/components/progress-part-bar/progress-part-bar.component';
import { UsageProfileEnum } from '../app/enums/UsageProfile.enum';
import { CatalogueSumDataByUsageProfile } from '../app/projects/audit/services/audit-subject-context.service';
import { mapAndTranslateIndicatorSubjects } from './mapping.helper';
import { SubjectIndicatorCatalogueValuesMap } from '../app/core/services/audit.service';
import { DefaultPieChartData } from '../app/core/components/charts/donut-chart/donut-chart.component';
import { chartLabels } from './translation.helper';
import { DefaultXyChartData } from '../app/core/services/am-chart.service';

export type FulfillmentProgressPartBarData = {
  dataParts: ProgressPart[];
  tooltipParts: ProgressPart[];
};

export type FulfillmentLevelData = {
  fulfillmentLevelName: string;
  fulfillmentLevelColor: HighlightColor;
  tooltip: string;
};

export type StackedColumnStatusChartData = {
  INACTIVE: number;
  DONE: number;
  IN_PROGRESS: number;
  OPEN: number;
  CRITICAL: number;
  OPTIONAL: number;
}

export type ColumnLinePerformanceChartData = {
  fulfillment: number;
  costs: number;
  euroPerFulfillment: number;
  euroPerClp: number;
  euroPerHu: number;
  clpPerFulfillment: number;
}

/**
 * Retrieves the fulfillment level summary data for a given sum value and type (icon that indicates the current level of fulfillment).
 *
 * @param {number} sumValue - The sum value to evaluate.
 * @param {FulfillmentLevelEntry[]} fulfillmentLevels - The array of fulfillment level entries to evaluate.
 * @returns {FulfillmentLevelData | null} The fulfillment level data, including name, color, and tooltip, or null if not found.
 */
export function getFulfillmentLevelSummaryData(sumValue: number,
                                               fulfillmentLevels: FulfillmentLevelEntry[]): FulfillmentLevelData | null {
  // Return null if the fulfillmentLevels array is not provided
  if (!fulfillmentLevels) return null;

  // Filter the fulfillment levels to find those that the sumValue meets or exceeds
  const currentFulfillmentLevelArr = fulfillmentLevels.filter(level => sumValue >= level.minimumPercentage);

  // Get the highest fulfillment level that the sumValue meets or exceeds
  const currentFulfillmentLevel: FulfillmentLevelEntry | null = currentFulfillmentLevelArr.length
    ? currentFulfillmentLevelArr[currentFulfillmentLevelArr.length - 1]
    : null;

  // Determine the name of the current fulfillment level, defaulting to 'noFulfillment' if none is found
  const fulfillmentLevelName: string = currentFulfillmentLevel?.name || 'noFulfillment';

  // Initialize the tooltip HTML string
  let tooltip: string = '<div class="fulfillment-level-tooltip">';

  // If no fulfillment level is met, add a specific message to the tooltip
  if (fulfillmentLevelName === 'noFulfillment') {
    tooltip += '<div><strong>Keine Mindesterfüllung erreicht</strong></div>';
  }

  // Iterate through all fulfillment levels to build the tooltip content
  for (const fulfillmentLevel of fulfillmentLevels) {
    if (fulfillmentLevel.name === fulfillmentLevelName) {
      // Highlight the current fulfillment level in the tooltip
      tooltip +=
        '<div class="fulfillment-level-tooltip-wrapper accent flexbox align-center default-gap justify-spaced">' +
        '<div class="fulfillment-level-tooltip-category">' +
        '<strong>' +
        fulfillmentLevel.name +
        ':</strong>' +
        '</div>' +
        '<div class="fulfillment-level-tooltip-values">' +
        '<strong>' +
        fulfillmentLevel.minimumPercentage +
        ' %</strong>' +
        '</div>' +
        '</div>';
      continue;
    }

    // Add other fulfillment levels to the tooltip
    tooltip +=
      '<div class="fulfillment-level-tooltip-wrapper flexbox align-center default-gap justify-spaced">' +
      '<div class="fulfillment-level-tooltip-category">' +
      fulfillmentLevel.name +
      ':' +
      '</div>' +
      '<div class="fulfillment-level-tooltip-values">' +
      '<strong>' +
      fulfillmentLevel.minimumPercentage +
      ' %</strong>' +
      '</div>' +
      '</div>';
  }
  tooltip += '</div>';

  // If no fulfillment level is met, return the default fulfillment level data
  if (fulfillmentLevelName === 'noFulfillment')
    return {
      fulfillmentLevelName,
      fulfillmentLevelColor: 'accent',
      tooltip: tooltip,
    };

  // Determine the color associated with the current fulfillment level
  const fulfillmentLevelColor: HighlightColor = getFulfillmentLevelColorFromName(fulfillmentLevelName);

  // Return the fulfillment level data including name, color, and tooltip
  return { fulfillmentLevelName, fulfillmentLevelColor, tooltip };
}

/**
 * Retrieves the highlight color associated with a given fulfillment level name.
 *
 * This function converts the provided fulfillment level name to lowercase and determines
 * the corresponding highlight color based on predefined criteria. The default color is 'accent'.
 *
 * @param {string} name - The name of the fulfillment level.
 * @returns {HighlightColor} The highlight color associated with the fulfillment level name.
 */
export function getFulfillmentLevelColorFromName(name: string): HighlightColor {
  let fulfillmentLevelColor: HighlightColor = 'accent';
  const levelName: string = name.toLowerCase();

  if (levelName.includes('bronze')) {
    fulfillmentLevelColor = 'bronze';
  } else if (levelName.includes('silver') || (levelName.includes('gut') && !levelName.includes('sehr gut'))) {
    fulfillmentLevelColor = 'silver';
  } else if (levelName.includes('gold') || levelName.includes('sehr gut')) {
    fulfillmentLevelColor = 'gold';
  } else if (levelName.includes('platinum') || levelName.includes('exzellent')) {
    fulfillmentLevelColor = 'platinum';
  }

  return fulfillmentLevelColor;
}

/**
 * Retrieves the color from the data context of an amCharts pie chart slice.
 *
 * @param {am5.Slice} target - The target slice from which to retrieve the color.
 * @param {string} [key="color"] - The key to use for retrieving the color from the data context.
 * @returns {am5.Color | undefined} The color from the data context, or undefined if not found.
 */
export function getColorFromAmPieChartDataContext(target: am5.Slice, key: string = 'color'): am5.Color | undefined {
  const dataItem = target.dataItem;
  if (dataItem) {
    return (dataItem.dataContext as DefaultPieChartData)[key as keyof DefaultPieChartData] as am5.Color;
  }
  return undefined;
}

/**
 * Retrieves the color from the data context of an amCharts column chart.
 * @param target - The target column from which to retrieve the color.
 * @param key - The key to use for retrieving the color from the data context.
 * @returns The color from the data context, or undefined if not found.
 */
export function getColorFromAmColumnChartDataContext(target: am5.RoundedRectangle, key: string = 'color'): am5.Color | undefined {
  const dataItem = target.dataItem;
  if (dataItem) {
    return (dataItem.dataContext as DefaultPieChartData)[key as keyof DefaultPieChartData] as am5.Color;
  }
  return undefined;
}

/**
 * Generates and retrieves fulfillment data based on the provided subject catalogue values map and DGNB system.
 *
 * @param {SubjectIndicatorCatalogueValuesMap} subjectCatalogueValuesMap - A map containing subject indicator catalogue values.
 * @param {DgnbSystem} dgnbSystem - The DGNB system object.
 * @param {ScenarioCollectionType} scenarioCollectionType - The type of scenario collection.
 * @returns {FulfillmentProgressPartBarData} An object containing data parts and tooltip parts for fulfillment progress.
 */
export function getFulfillmentDataByCatalogueValues(
  subjectCatalogueValuesMap: SubjectIndicatorCatalogueValuesMap,
  dgnbSystem: DgnbSystem,
  scenarioCollectionType: ScenarioCollectionType
): FulfillmentProgressPartBarData {
  const subjectValues: SubjectValue[] | undefined = dgnbSystem.subjectValues;
  if (!subjectValues?.length || !subjectCatalogueValuesMap.size) return { dataParts: [], tooltipParts: [] };

  const subjectProgressPartMap: Map<string, ProgressPart> = new Map<string, ProgressPart>();
  const upMap: Map<UsageProfileEnum, number> = new Map<UsageProfileEnum, number>();

  for (const [subject, values] of subjectCatalogueValuesMap) {
    const subjectValue: SubjectValue | undefined = subjectValues.find(value => value.subject === subject.toLowerCase());
    if (!subjectValue) continue;
    const subjectSumDataByUsageProfiles: CatalogueSumDataByUsageProfile[]
      = values.map(value => value.sumDataByUsageProfile)
      .flat()
      .map(data => ({ ...data, subject: subject }));
    for (const sumData of subjectSumDataByUsageProfiles) {
      if (!sumData.usageProfileKey || !sumData.catalogueMaxClp) continue;
      // Determine the sum data to accumulate based on the scenario type
      const sumDataToAccumulate = scenarioCollectionType === 'preCheckScenarios'
        ? sumData.preCheckCatalogueSum
        : sumData.assessmentCatalogueSum;

      // Determine the adgnbValue based on the mixed usage profile type
      // If the mixed usage profile type is 'SP', use the sumData.adgnbValue
      // Otherwise, default to 100
      const adgnbValue = sumData.mixedUsageProfileType === 'SP' ? sumData.adgnbValue : 100;

      // Calculate the total share value
      const totalShareValue: number = sumDataToAccumulate * (sumData.shareValue || 0) / sumData.catalogueMaxClp;

      // Calculate and return the total sum value
      const totalSumValue: number = (adgnbValue / 100) * totalShareValue;

      // Calculate the subject share value
      const subjectShareValue = ((sumData.shareValue || 0) / (subjectValue.subjectPercentage || 1)) * 100;

      // Calculate the subject value
      const subjectValueCalculated = subjectShareValue * sumDataToAccumulate / sumData.catalogueMaxClp;

      // Calculate and return the subject sum value
      const subjectSumValue: number = (adgnbValue / 100) * subjectValueCalculated;

      const _subjectPart: ProgressPart | undefined = subjectProgressPartMap.get(subject);
      const _tooltipSumValue: number = (_subjectPart?.value || 0) + (sumDataToAccumulate * adgnbValue) / 100;
      const _tooltipTotalSumPercent: number = (_subjectPart?.percent || 0) + totalSumValue;
      const _tooltipSubjectSumPercent: number = (_subjectPart?.secondaryValue || 0) + subjectSumValue;

      subjectProgressPartMap.set(subject, {
        key: subject,
        label: mapAndTranslateIndicatorSubjects(subject),
        value: _tooltipSumValue,
        maxValue: values.map(v => v.maxClp).reduce((a, v) => a + v, 0),
        percent: _tooltipTotalSumPercent,
        secondaryValue: _tooltipSubjectSumPercent
      });
      upMap.set(sumData.usageProfileKey, (upMap.get(sumData.usageProfileKey) || 0) + totalSumValue);
    }
  }

  // Create tooltip parts from the accumulated values in the map
  const tooltipParts: ProgressPart[] = Array.from(subjectProgressPartMap.entries()).map(([key, value]) => ({
    key: key,
    label: value.label,
    value: value.value,
    maxValue: value.maxValue,
    percent: value.percent,
    secondaryValue: value.secondaryValue
  }));

  // Calculate the total sum of the data parts
  const dataSum = Array.from(upMap.values()).reduce((acc, value) => acc + value, 0);

  // Create data parts with the total sum
  const dataParts: ProgressPart[] = [
    {
      percent: dataSum,
      key: 'sum',
      color: 'accent' as HighlightColor,
    },
  ];

  return { dataParts, tooltipParts };
}

/**
 * Translates the keys of the column chart data.
 *
 * This function takes an array of DefaultStackedColumnChartData objects and translates
 * their keys based on the chartLabels mapping. Keys 'category', 'color', and 'priority'
 * are kept as is, while other keys are translated using the chartLabels mapping.
 *
 * @param {DefaultXyChartData[]} chartData - The array of chart data to be translated.
 * @returns {DefaultXyChartData[]} The array of translated chart data.
 */
export function translateStackedColumnChartDataKeys(chartData: DefaultXyChartData[]): DefaultXyChartData[] {
  return chartData.map(data => {
    const res: DefaultXyChartData = {};
    for (const key in data) {
      res[key === 'category' || key === 'color' || key === 'priority' ? key : chartLabels[key]] = data[key];
    }
    return res;
  });
}
