import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { IndicatorIdentificationData, PreCheckScenario } from '@eeule/eeule-shared';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { TypeSafeMatCellDef } from '../../../core/directives/TypeSafeMatCellDef';
import { mapAndTranslateIndicatorSubjects } from '../../../../util/mapping.helper';
import { DgnbSubjectEnum } from '../../../enums/DgnbSubject.enum';
import { Router, UrlTree } from '@angular/router';
import { ProjectService } from '../../../core/services/project.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { catchError, forkJoin, map, Observable, of, takeUntil, throwError } from 'rxjs';
import { BaseComponent } from '../../../core/components/base/base.component';

type TaskIndicatorReferenceTableData = {
  type: string,
  scenarioId: string,
  groupId: string,
  catalogueId: string,
  indicatorId: string,
  scenarioName: string,
  subject: string,
  indicatorInfo: string,
  path: string
}

@Component({
  selector: 'eule-task-indicator-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatSortModule,
    MatTableModule,
    TypeSafeMatCellDef,
    MatPaginatorModule,

  ],
  templateUrl: './task-indicator-list.component.html',
  styleUrl: './task-indicator-list.component.scss',
})
export class TaskIndicatorListComponent extends BaseComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = ['type', 'scenarioName', 'subject', 'indicatorInfo'];
  public dataSource: MatTableDataSource<TaskIndicatorReferenceTableData> = new MatTableDataSource<TaskIndicatorReferenceTableData>();
  // public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // public isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input({ required: true }) taskIndicatorReferences: IndicatorIdentificationData[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  constructor(
    private _router: Router,
    private _projectService: ProjectService,
    private _snackbarService: SnackbarService,
  ) {
    super();
  }

  ngOnInit() {
    this.getScenariosByIndicatorReferencesAsObservable().pipe(
      catchError(error => {
        this._snackbarService.showErrorMessage('Beim Abrufen der PreCheck- oder Audit-Szenarien ist ein Fehler aufgetreten.');
        return throwError(() => error);
      }),
      takeUntil(this.stop$))
      .subscribe(scenarios => {
        this.dataSource.data = this.taskIndicatorReferences
          .filter(ref => scenarios.some(sc => (
            ref.scenarioId === sc.id
          )))
          .map(o => ({
          type: o.type === 'audit' ? 'Audit' : 'Pre-Check',
          scenarioId: o.scenarioId,
          groupId: o.groupId,
          catalogueId: o.catalogueId,
          indicatorId: o.indicatorId,
          scenarioName: o.scenarioName,
          subject: mapAndTranslateIndicatorSubjects(o.subject.toLowerCase() as DgnbSubjectEnum),
          indicatorInfo: `${o.catalogueName || ''} ${o.indicatorNumber || ''} ${o.indicatorName || o.indicatorId || ''}`,
          path: `${o.type === 'audit' ? 'audit-details' : 'pre-check'}/${o.subject}`,
        }));
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openIndicator(row: TaskIndicatorReferenceTableData) {
    const projectId: string | undefined = this._projectService.project$.value?.id;
    if (!projectId) {
      this._snackbarService.showErrorMessage('Beim Abrufen des Projektes ist ein Fehler aufgetreten.');
      throw new Error('an error occurred while retrieving the current project subscription value.');
    }
    const querParams = {
      scenarioId: row.scenarioId,
      groupId: row.groupId,
      catalogueId: row.catalogueId,
      indicatorId: row.indicatorId,
    };
    const urlTree: UrlTree = this._router.createUrlTree([`/intern/project/${projectId}/audit/${row.path}`], { queryParams: querParams });
    const url: string = this._router.serializeUrl(urlTree);
    window.open(url, '_blank');
  }

  /**
   * Retrieves all scenarios (both pre-check and audit) referenced by task indicators.
   * @returns {Observable<PreCheckScenario[]>} An observable that emits an array of all referenced scenarios.
   */
  private getScenariosByIndicatorReferencesAsObservable(): Observable<PreCheckScenario[]> {
    // Extract pre-check scenario IDs from taskIndicatorReferences
    const preCheckScenarioIds: string[] = Array.from(new Set(this.taskIndicatorReferences
      .filter(o => o.type === 'preCheck')
      .map(o => o.scenarioId),
    ));

    if (!preCheckScenarioIds.length) return of([]);

    // Get observable for pre-check scenarios
    const preCheckScenarios$ = this._projectService.getScenariosByIds(preCheckScenarioIds);

    // Extract audit scenario IDs from taskIndicatorReferences
    const auditScenarioIds: string[] = this.taskIndicatorReferences
      .filter(o => o.type === 'audit') // Correct filter for audit scenarios
      .map(o => o.scenarioId);

    // If no audit scenarios, return only pre-check scenarios observable
    if (!auditScenarioIds.length) return preCheckScenarios$;

    // Get observable for audit scenarios
    const auditScenarios$ = this._projectService.getScenariosByIds(auditScenarioIds, 'auditScenarios');

    // Combine both observables and merge their results
    return forkJoin([preCheckScenarios$, auditScenarios$]).pipe(
      map(([preCheckScenarios, auditScenarios]) => {
        return [...preCheckScenarios, ...auditScenarios];
      }),
    );
  }
}
