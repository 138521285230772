import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import { Color } from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { AmChartService, ColorSetType } from '../../../services/am-chart.service';
import { TextColors, ThemeService } from '../../../services/theme.service';
import * as am5locales from '@amcharts/amcharts5/locales/de_DE';

@Component({
  selector: 'eule-am-charts-base',
  standalone: true,
  imports: [],
  template: '',
  styles: [''],
})
export class AmChartsBaseComponent<T> implements AfterViewInit, OnChanges, OnDestroy {
  /** The ID of the chart. */
  @Input({ required: true }) chartId!: string;

  /** The data for the chart. */
  @Input({ required: true }) chartData!: T[];

  /** The type of color set to use for the chart. */
  @Input() colorSetType?: ColorSetType = 'default';

  /** Whether the chart has a legend. */
  @Input() hasLegend: boolean = true;

  /** The custom color set for the chart. */
  @Input() colorSet?: Color[];

  /** Whether the grid is disabled. */
  @Input() disableGrid?: boolean = false;

  /** Whether the X axis grid is disabled. */
  @Input() disableGridX?: boolean = false;

  /** Whether the Y axis grid is disabled. */
  @Input() disableGridY?: boolean = false;

  /** Whether minor grid is enabled. */
  @Input() enableMinorGrid?: boolean = true;

  /** Whether to disable the X scrollbar. */
  @Input() disableScrollbarX?: boolean = false;

  /** Whether scroll wheel is disabled. */
  @Input() disableScrollWheel?: boolean = false;

  /** The padding left of the chart. */
  @Input()  paddingLeft?: number = 0;

  /** Whether to use the extended color palette. (doubles included colors) */
  @Input() extendedColorPalette?: boolean = false;

  /** The start index of the color palette. */
  @Input() colorPaletteStartIndex?: number;

  /** The end index of the color palette. */
  @Input() colorPaletteEndIndex?: number;

  /** The step size of the color palette. */
  @Input() colorPaletteStepSize?: number;

  /** The reference to the chart element. */
  @ViewChild('amChart') chartElement!: ElementRef<HTMLElement>;

  /** The root element of the chart. */
  public root?: am5.Root;

  /** The color set to apply. */
  public colorSetToApply?: Color[] | null;

  /** The text colors for the chart. */
  public textColors?: TextColors;

  constructor(
    public _chartService: AmChartService,
    public _themeService: ThemeService,
  ) {
  }

  /**
   * Initializes the chart after the view has been initialized.
   */
  ngAfterViewInit(): void {
    this._chartService.browserOnly(() => this.generateChart());
  }

  /**
   * Handles changes to the chart data.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    const chartDataChange: SimpleChange = changes['chartData'];
    if (this.root && chartDataChange.currentValue && !chartDataChange.firstChange) {
      this.root.dispose();
      this._chartService.browserOnly(() => this.generateChart());
    }
  }

  /**
   * Cleans up the chart and unsubscribes from theme changes when the component is destroyed.
   */
  ngOnDestroy(): void {
    if (this.root) this._chartService.browserOnly(() => this.root!.dispose());
  }

  /**
   * Generates the chart.
   *
   * This function initializes the chart by setting the color set to apply and text colors,
   * and then creates a new root element with the specified theme.
   */
  generateChart() {
    if (!this.chartData) return;

    this.colorSetToApply = this._chartService.getColorSetToApply(
      this.colorSetType,
      this.colorSet,
      this.extendedColorPalette,
      this.colorPaletteStartIndex,
      this.colorPaletteEndIndex,
      this.colorPaletteStepSize
    );

    this.textColors = this._themeService.themeTextColors;

    // Create a new root and add theme
    this.root = this._chartService.generateRootWithTheme(this.chartElement.nativeElement, this.colorSetToApply);

    this.root.locale = am5locales.default;

    this.root.numberFormatter.set("numberFormat", "#.###,##");

    // Prepare the chart data with optional color set
    this.chartData = this.colorSetToApply
      ? this.chartData.map((item, index) => (
        { ...item, color: this.colorSetToApply![index] }
      ))
      : this.chartData;
  }

  /**
   * Generates an XY chart with the configured settings.
   *
   * This function creates a new XY chart, configures its properties such as panning and zooming behavior,
   * and adds it to the root container. It also creates and customizes the horizontal scrollbar if it is not disabled.
   *
   * @returns {am5xy.XYChart} The generated XY chart.
   */
  generateXyChart(): am5xy.XYChart {
    const chart = this.root!.container.children.push(am5xy.XYChart.new(this.root!, {
      panX: false,
      panY: false,
      wheelX: 'panX',
      paddingLeft: this.paddingLeft,
      wheelY: this.disableScrollWheel ? 'none' : 'zoomX',
      layout: this.root!.verticalLayout,
    }));

    // Create and customize the horizontal scrollbar
    if (!this.disableScrollbarX) {
      const scrollbarX = this.generateDefaultScrollbarX();
      chart.set('scrollbarX', scrollbarX);
    }

    return chart;
  }

  /**
   * Generates a legend for the XY chart.
   *
   * This function creates a new legend for the provided XY chart and sets its position.
   * If text colors are defined, it also sets the fill color and font size for the legend labels and value labels.
   *
   * @param {am5xy.XYChart} chart - The XY chart for which the legend is generated.
   * @returns {am5.Legend | undefined} The generated legend, or undefined if the root is not defined.
   */
  generateXyLegend(chart: am5xy.XYChart): am5.Legend | undefined {
    if (!this.root) return;

    const legend = chart.children.push(am5.Legend.new(this.root, {
      centerX: am5.p50,
      x: am5.p50,
    }));

    if (this.textColors) {
      legend.labels.template.setAll({
        fill: am5.color(this.textColors.primaryText),
        fontSize: 12,
      });

      legend.valueLabels.template.setAll({
        fill: am5.color(this.textColors.primaryText),
        fontSize: 12,
      });
    }

    return legend;
  }

  /**
   * Retrieves the default horizontal scrollbar with the specified colors.
   *
   * This function creates a new horizontal scrollbar and sets its colors based on the provided text colors.
   * If the root or text colors are not defined, it returns undefined.
   *
   * @returns {am5.Scrollbar | undefined} The configured horizontal scrollbar, or undefined if the root or text colors are not defined.
   */
  generateDefaultScrollbarX(): am5.Scrollbar | undefined {
    if (!this.root || !this.textColors) return;
    const scrollbarX = am5.Scrollbar.new(this.root, {
      orientation: 'horizontal',
    });

    // Set scrollbar colors
    scrollbarX.get('background')!.setAll({
      fill: am5.color(this.textColors.divider),  // Background color
    });

    scrollbarX.thumb.setAll({
      fill: am5.color(this.textColors.divider),
    });

    scrollbarX.startGrip.get('background')!.setAll({
      fill: am5.color(this.textColors.divider),  // Background color
      stroke: am5.color(this.textColors.secondaryDivider),
    });

    scrollbarX.endGrip.get('background')!.setAll({
      fill: am5.color(this.textColors.divider),  // Background color
      stroke: am5.color(this.textColors.secondaryDivider),
    });

    return scrollbarX;
  }

  /**
   * Generates the default X-axis renderer with the specified properties.
   *
   * This function configures the X-axis renderer by setting the minor grid, stroke color,
   * stroke opacity, grid template properties, and label properties based on the provided text colors.
   * If the root or text colors are not defined, it returns undefined.
   *
   * @returns {am5xy.AxisRendererX | undefined} The configured X-axis renderer, or undefined if the root or text colors are not defined.
   */
  generateDefaultXAxisRenderer(): am5xy.AxisRendererX | undefined {
    if (!this.root || !this.textColors) return;

    // Configure the x-axis renderer
    const xAxisRenderer = am5xy.AxisRendererX.new(this.root, {
      minorGridEnabled: this.enableMinorGrid,
      stroke: am5.color(this.textColors.secondaryText),
      strokeOpacity: this.disableGrid || this.disableGridX
        ? 0.2
        : 0.1,
    });

    // Set grid template properties for the x-axis
    xAxisRenderer.grid.template.setAll({
      stroke: am5.color(this.textColors.secondaryText),
      strokeOpacity: this.disableGrid || this.disableGridX ? 0 : .1,
      location: 1,
    });

    // Set label properties for the x-axis
    xAxisRenderer.labels.template.setAll({
      fill: am5.color(this.textColors.primaryText),
    });

    return xAxisRenderer;
  }

  /**
   * Generates the default Y-axis renderer with the specified properties.
   *
   * This function configures the Y-axis renderer by setting the stroke color,
   * stroke opacity, grid template properties, and label properties based on the provided text colors.
   * If the root or text colors are not defined, it returns undefined.
   *
   * @returns {am5xy.AxisRendererY | undefined} The configured Y-axis renderer, or undefined if the root or text colors are not defined.
   */
  generateDefaultYAxisRenderer(): am5xy.AxisRendererY | undefined {
    if (!this.root || !this.textColors) return;

    // Create and configure the y-axis with label color on renderer
    const yAxisRenderer = am5xy.AxisRendererY.new(this.root, {
      stroke: am5.color(this.textColors.secondaryText),
      strokeOpacity: this.disableGrid || this.disableGridY
        ? 0.2
        : 0.1,
    });

    // Set the color of the value axis labels via renderer
    yAxisRenderer.labels.template.setAll({
      fill: am5.color(this.textColors.primaryText),
    });

    yAxisRenderer.grid.template.setAll({
      stroke: am5.color(this.textColors.secondaryText),
      strokeOpacity: this.disableGrid || this.disableGridY ? 0 : .1,
    });

    return yAxisRenderer;
  }

}
