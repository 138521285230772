import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import { Color } from '@amcharts/amcharts5';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { euleDarkChartColors, euleDefaultChartColors } from '../../../constants/am-chart-colors';
import { HighlightColors, ThemeService } from './theme.service';
import { DgnbIndicatorX, getIndicatorStatusMap } from '../../../util/indicator.helper';
import { IndicatorStatusEnum } from '../../enums/IndicatorStatus.enum';
import { removeEveryNthEntry, sortByKey } from '../../../util/array-like.helper';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { DefaultPieChartData } from '../components/charts/donut-chart/donut-chart.component';
import {
  AuditService,
  DgnbCriteriaGroupWithSubCollections,
  ScenarioWithSubCollections,
  SubjectIndicatorCatalogueValuesMap,
} from './audit.service';
import {
  ColumnLinePerformanceChartData,
  FulfillmentProgressPartBarData,
  getFulfillmentDataByCatalogueValues,
  StackedColumnStatusChartData,
} from '../../../util/visualization.helper';
import { getEnumKeyByValue } from '../../../util/enum.helper';
import { DgnbSystem, Project } from '@eeule/eeule-shared';
import { ScenarioCollectionType } from '../../types/common-types';
import { getAmColorsFromHexStrings } from '../../../util/am-charts.helper';
import { MATERIAL_COLOR_PALETTE_MAP, MaterialPaletteColor } from '../../../constants/material-colors';

export interface DefaultChartData<T = string> {
  /** The name of the category for the chart's x-axis. */
  category?: T;
  color?: am5.Color;
  priority?: number;
}

export type ColumnLineSeriesType = 'column' | 'line';

export type DefaultXyChartData = DefaultChartData & {
  [key: string]: number | string | undefined | am5.Color;
  category?: string;
};

export type AmChartColorSetType = 'eule' | 'status' | 'default' | 'custom' | null | undefined;

export type ColorSetType = MaterialPaletteColor | AmChartColorSetType;

@Injectable({
  providedIn: 'root',
})
export class AmChartService {
  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private zone: NgZone,
              private _themeService: ThemeService,
              private _auditService: AuditService) {
    if (isPlatformBrowser(this.platformId)) {
      am5.addLicense(environment.amChartsKey);
    }

  }

  /**
   * Executes a function only in the browser environment.
   *
   * @param {() => void} f - The function to execute.
   */
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  /**
   * Generates a root element with a theme.
   * @param chartElement
   * @param colorSetToApply
   */
  generateRootWithTheme(chartElement: HTMLElement, colorSetToApply?: am5.Color[] | null): am5.Root {
    const root = am5.Root.new(chartElement);
    root.numberFormatter.set('numberFormat', '#.###,##');
    if (colorSetToApply) {
      root.setThemes([
        am5themes_Animated.new(root),
        this.createCustomTheme(root, colorSetToApply),
      ]);
    } else {
      root.setThemes([
        am5themes_Animated.new(root),
      ]);
    }

    return root;
  }

  /**
   * Creates a custom theme for the chart.
   *
   * @param {am5.Root} root - The root element of the chart.
   * @param {am5.Color[]} [colors] - Optional colors for the theme.
   * @returns {am5.Theme} The custom theme.
   */
  createCustomTheme(root: am5.Root, colors?: am5.Color[]): am5.Theme {
    const customTheme = am5.Theme.new(root);
    customTheme.rule('ColorSet').setAll({
      colors: colors || this.euleColorSet,
    });
    return customTheme;
  }

  /**
   * Retrieves the color set based on the current theme.
   *
   * @returns {Color[]} The color set.
   */
  get euleColorSet(): Color[] {
    switch (this._themeService.themeSig()) {
      case 'dark-theme':
        return euleDarkChartColors;
      default:
        return euleDefaultChartColors;
    }
  }

  /**
   * Retrieves the color set to apply based on the color set type.
   * @param colorSetType - The color set type.
   * @param colorSet - The color set to apply.
   * @param extended - Whether to return an extended color set.
   * @param paletteStartIndex - The start index of the color palette.
   * @param paletteEndIndex - The end index of the color palette.
   * @param paletteStepSize - The step size of the color palette.
   *
   * @returns {Color[] | null} The color set to apply.
   */
  getColorSetToApply(
    colorSetType: ColorSetType,
    colorSet?: am5.Color[],
    extended?: boolean,
    paletteStartIndex?: number,
    paletteEndIndex?: number,
    paletteStepSize?: number,
  ): Color[] | null {
    switch (colorSetType) {
      case 'eule':
        return this.euleColorSet;
      case null:
      case undefined:
        return colorSet || null;
      default: {
        const materialColors: Color[] | undefined = extended
          ? [
            ...getAmColorsFromHexStrings(MATERIAL_COLOR_PALETTE_MAP.get(colorSetType)!),
            ...getAmColorsFromHexStrings(MATERIAL_COLOR_PALETTE_MAP.get(colorSetType)!),
          ]
          : getAmColorsFromHexStrings(MATERIAL_COLOR_PALETTE_MAP.get(colorSetType)!);
        return paletteStepSize === 1
          ? materialColors?.slice(paletteStartIndex || 3, materialColors.length - (paletteEndIndex || 0))
          : removeEveryNthEntry(
          materialColors?.slice(paletteStartIndex || 3, materialColors.length - (paletteEndIndex || 0)), paletteStepSize || 2,
        ) || null;
      }
    }
  }

  /**
   * Retrieves a status color map based on the current theme.
   */
  get statusColorMap(): Map<string, string> {
    const highlightColors: HighlightColors = this._themeService.themeHighlightColors;
    return new Map<string, string>([
      ['OPEN', highlightColors.accent],
      ['IN_PROGRESS', highlightColors.info],
      ['DONE', highlightColors.primary],
      ['INACTIVE', highlightColors.inactive],
      ['CRITICAL', highlightColors.warn],
      ['OPTIONAL', highlightColors.optional],
    ]);
  }

  /**
   * Retrieves a map of status priorities.
   *
   * @returns {Map<string, string>} A map where the key is the status and the value is the priority.
   */
  get statusPriorityMap(): Map<string, number> {
    return new Map<string, number>([
      ['DONE', 1],
      ['IN_PROGRESS', 2],
      ['INACTIVE', 3],
      ['OPEN', 4],
      ['CRITICAL', 5],
      ['OPTIONAL', 6],
    ]);
  }

  /**
   * Retrieves the color for a given status.
   * @param status
   */
  getTranslatedStatusColor(status: string): string {
    const enumKey = getEnumKeyByValue(IndicatorStatusEnum, status);
    return this.statusColorMap.get(enumKey || status) || '';
  }

  /**
   * Generates indicator status chart data from a list of indicators.
   *
   * @param {DgnbIndicatorX[]} indicators - The list of indicators to process.
   * @returns {DefaultPieChartData[]} The generated status chart data.
   */
  getIndicatorStatusPieChartData(indicators: DgnbIndicatorX[]): DefaultPieChartData[] {
    const highlightColors: HighlightColors = this._themeService.themeHighlightColors;
    const statusMap: Map<string, DgnbIndicatorX[]> = getIndicatorStatusMap(indicators);

    // Convert the status map to an array of DefaultPieChartData objects
    const statusChartData: DefaultPieChartData[] = Array.from(statusMap, ([name, value]) => ({ name, value }))
      .map(item => {
        const colorString = this.statusColorMap.get(item.name);
        const colorToApply = colorString
          ? am5.color(colorString)
          : am5.color(highlightColors.accent);
        return {
          category: IndicatorStatusEnum[item.name as keyof typeof IndicatorStatusEnum],
          value: item.value.length,
          color: colorToApply,
          priority: this.statusPriorityMap.get(item.name) || 0,
        };
      });
    return sortByKey(statusChartData, 'priority');
  }

  /**
   * Transforms subject groups into subject status chart data.
   *
   * This function takes a map of subject groups and transforms it into an array of
   * DefaultStackedColumnChartData objects. Each entry in the map represents a category
   * and contains an array of DgnbCriteriaGroupWithSubCollections. The function counts
   * the status of each indicator within the groups and aggregates these counts into
   * a DefaultStackedColumnChartData object for each category.
   *
   * @param {Map<string, DgnbCriteriaGroupWithSubCollections[]>} subjectGroups - A map where the key is the category name and the value is an array of DgnbCriteriaGroupWithSubCollections.
   * @returns {DefaultXyChartData[]} An array of DefaultStackedColumnChartData objects representing the aggregated status counts for each category.
   */
  getScenarioStatusColumnChartData(subjectGroups: Map<string, DgnbCriteriaGroupWithSubCollections[]>): DefaultXyChartData[] {
    const chartData: DefaultXyChartData[] = [];

    // Initialize the total counters
    const totalCounters: StackedColumnStatusChartData = {
      INACTIVE: 0,
      DONE: 0,
      IN_PROGRESS: 0,
      OPEN: 0,
      CRITICAL: 0,
      OPTIONAL: 0,
    };

    subjectGroups.forEach((groups, category) => {
      // Initialize counters
      const categoryCounters: StackedColumnStatusChartData = {
        INACTIVE: 0,
        DONE: 0,
        IN_PROGRESS: 0,
        OPEN: 0,
        CRITICAL: 0,
        OPTIONAL: 0,
      };

      groups.forEach(group => {
        group.catalogues?.forEach(catalogue => {
          catalogue.indicators?.forEach(indicator => {
            const status = indicator.data?.indicatorStatus?.toUpperCase() || 'INACTIVE';
            if (categoryCounters[status as keyof StackedColumnStatusChartData] !== undefined) {
              categoryCounters[status as keyof StackedColumnStatusChartData]++;
            }
          });
        });
      });

      // Update the total counters
      Object.keys(totalCounters).forEach((key) => {
        totalCounters[key as keyof StackedColumnStatusChartData] += categoryCounters[key as keyof StackedColumnStatusChartData];
      });

      // Prepare the data for this category
      chartData.push({
        category: category,
        INACTIVE: categoryCounters.INACTIVE,
        DONE: categoryCounters.DONE,
        IN_PROGRESS: categoryCounters.IN_PROGRESS,
        OPEN: categoryCounters.OPEN,
        CRITICAL: categoryCounters.CRITICAL,
        OPTIONAL: categoryCounters.OPTIONAL,
      });
    });

    // Add the total entry at the beginning of the result array
    chartData.unshift({
      category: 'total',
      INACTIVE: totalCounters.INACTIVE,
      DONE: totalCounters.DONE,
      IN_PROGRESS: totalCounters.IN_PROGRESS,
      OPEN: totalCounters.OPEN,
      CRITICAL: totalCounters.CRITICAL,
      OPTIONAL: totalCounters.OPTIONAL,
    });

    return chartData;
  }

  /**
   * Generates performance column line chart data based on the provided scenario data.
   *
   * This function processes the scenario data to generate chart data for performance metrics.
   * It calculates various metrics such as total fulfillment, total costs, and costs per housing unit.
   * The function returns an array of chart data objects, including a total entry and individual subject entries.
   *
   * @param {ScenarioWithSubCollections} scenarioData - The scenario data containing subject groups and their sub-collections.
   * @param {Project} project - The project data containing information about the project.
   * @param {DgnbSystem} dgnbSystem - The DGNB system used for the project.
   * @param {ScenarioCollectionType} scenarioCollectionType - The type of scenario collection.
   * @returns {DefaultXyChartData[]} An array of chart data objects representing performance metrics.
   */
  getPerformanceColumnLineChartData(
    scenarioData: ScenarioWithSubCollections,
    project: Project,
    dgnbSystem: DgnbSystem,
    scenarioCollectionType: ScenarioCollectionType,
  ): DefaultXyChartData[] {
    // Generate and retrieve the subject catalogue values map
    const subjectCatalogueValuesMap: SubjectIndicatorCatalogueValuesMap
      = this._auditService.generateAndGetSubjectCatalogueValuesMap(scenarioData);

    // Retrieve the fulfillment progress data
    const progressData: FulfillmentProgressPartBarData = getFulfillmentDataByCatalogueValues(
      subjectCatalogueValuesMap,
      dgnbSystem,
      scenarioCollectionType,
    );

    // Calculate the total fulfillment percentage
    const totalFulfillment: number = progressData.dataParts
      .map(o => o.percent)
      .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;

    // Calculate the total CLP (Certified Life Points)
    const totalClp: number = progressData.tooltipParts
      .map(o => o.value)
      .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;

    // Calculate the total costs of actions
    const totalCosts: number = this._auditService.generateSubjectCosts(scenarioData);

    // Retrieve the number of housing units from the project
    const housingUnits: number = project.numberOfResidentialUnits || 0;

    // Calculate the total costs per housing unit
    const totalCostsPerHousingUnit: number = housingUnits
      ? totalCosts / housingUnits
      : totalCosts;

    // Calculate the total costs per fulfillment percentage
    const totalCostsPerFulfillment: number = totalCosts / totalFulfillment;

    // Calculate the total costs per CLP
    const totalCostsPerClp: number = totalCosts / totalClp;

    // Calculate the total CLP per fulfillment percentage
    const totalClpPerFulfillment: number = totalClp / totalFulfillment;

    // Create the total entry for the chart data
    const totals: DefaultXyChartData & ColumnLinePerformanceChartData = {
      category: 'total',
      fulfillment: parseFloat(totalFulfillment.toFixed(2)),
      costs: totalCosts,
      euroPerFulfillment: parseFloat(totalCostsPerFulfillment.toFixed(2)),
      euroPerClp: parseFloat(totalCostsPerClp.toFixed(2)),
      euroPerHu: parseFloat(totalCostsPerHousingUnit.toFixed(2)),
      clpPerFulfillment: parseFloat(totalClpPerFulfillment.toFixed(2)),
    };

    // Generate the subject data for the chart
    const subjectData: Array<DefaultXyChartData & ColumnLinePerformanceChartData> = progressData.tooltipParts.map(o => {
      const subjectCosts: number = this._auditService.generateSubjectCosts(scenarioData, o.key);
      return {
        category: o.key,
        fulfillment: o.percent ? parseFloat(o.percent.toFixed(2)) : 0,
        costs: subjectCosts,
        euroPerFulfillment: parseFloat((o.percent ? subjectCosts / o.percent : 0).toFixed(2)),
        euroPerClp: parseFloat((o.value ? subjectCosts / o.value : 0).toFixed(2)),
        euroPerHu: parseFloat((housingUnits ? subjectCosts / housingUnits : 0).toFixed(2)),
        clpPerFulfillment: parseFloat((o.percent && o.value ? o.value / o.percent : 0).toFixed(2)),
      };
    });

    // Add the total entry at the beginning of the result array
    subjectData.unshift(totals);

    return subjectData;
  }

}
