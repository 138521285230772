import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AmChartService, DefaultChartData } from '../../../services/am-chart.service';
import * as am5 from '@amcharts/amcharts5';
import { TextColors, ThemeService } from '../../../services/theme.service';
import * as am5percent from '@amcharts/amcharts5/percent';
import { CustomTooltipDirective } from '../../../directives/custom-tooltip.directive';
import { getColorFromAmPieChartDataContext } from '../../../../../util/visualization.helper';
import { AmChartsBaseComponent } from '../am-charts-base/am-charts-base.component';

export type DefaultPieChartData<T = string> = DefaultChartData<T> & {
  value: number;
}

@Component({
  selector: 'eule-donut-chart',
  standalone: true,
  imports: [
    CustomTooltipDirective,
  ],
  templateUrl: './donut-chart.component.html',
  styleUrl: './donut-chart.component.scss',
})
export class DonutChartComponent extends AmChartsBaseComponent<DefaultPieChartData> {
  /** The height of the chart. */
  @Input() height: number = 100;

  /** The number of visible legend items. */
  @Input() numberOfVisibleLegendItems?: number;

  /** Whether the chart has a tooltip. */
  @Input() hasTooltip: boolean = true;

  /** The y position of the donut chart. */
  @Input() donutYPosition: number = 0;

  /** The position of the tooltip. */
  @Input() tooltipPosition: 'bottom' | 'top' = 'bottom';

  /** X Offset for the tooltip position. */
  @Input() tooltipPositionOffsetX: number = 0;

  /** Y Offset for the tooltip position. */
  @Input() tooltipPositionOffsetY: number = 0;

  /** The series of the chart. */
  private series?: am5percent.PieSeries;

  /** The tooltip content. */
  tooltip: string | null = null;

  /**
   * Constructs the DonutChartComponent.
   *
   * @param {AmChartService} _chartService - The chart service.
   * @param {ThemeService} _themeService - The theme service.
   * @param {ChangeDetectorRef} _cdRef - The change detector reference.
   */
  constructor(_chartService: AmChartService,
              _themeService: ThemeService,
              private _cdRef: ChangeDetectorRef) {
    super(_chartService, _themeService);
  }

  /**
   * Generates the chart.
   */
  override generateChart() {
    super.generateChart();

    if (!this.root) return;

    // Create chart container with horizontal layout
    const chartContainer = this.root.container.children.push(
      am5.Container.new(this.root, {
        layout: this.root.horizontalLayout, // Set layout to horizontal
        width: am5.percent(100),
        height: am5.percent(100),
      }),
    );

    // Create chart and add it to the right
    const chart = chartContainer.children.push(
      am5percent.PieChart.new(this.root, {
        layout: this.root.verticalLayout,
        innerRadius: am5.percent(50),
        x: am5.percent(50),
        y: am5.percent(this.donutYPosition),
        height: am5.percent(100),
      }),
    );

    // Create series
    this.series = chart.series.push(
      am5percent.PieSeries.new(this.root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false,
        legendValueText: '{value}',
      }),
    );

    // Disable labels and ticks
    this.series.labels.template.setAll({
      visible: false,
      forceHidden: true,
    });
    this.series.ticks.template.setAll({
      visible: false,
      forceHidden: true,
    });

    if (this.chartData.length && this.chartData[0].color && !this.colorSetToApply) {
      this.assignSliceSpecificColors();
    }

    // Set data and individual slice colors
    this.series.data.setAll(this.chartData);

    this.generateLegend(chartContainer);

    // Play initial series animation
    this.series.appear(1000, 100).catch((e) => console.error(e));

    // Set the tooltip
    this.tooltip = this.getLegendTooltip(this.chartData);

    this._cdRef.detectChanges();  // <-- Detect changes manually
  }

  /**
   * Generates the legend for the chart.
   *
   * @param {am5.Container} chartContainer - The chart container.
   */
  private generateLegend(chartContainer: am5.Container) {
    if (!this.root || !this.series) return;
    // Create legend and add it to the left
    const legend = chartContainer.children.push(
      am5.Legend.new(this.root, {
        layout: this.root.verticalLayout,
        y: am5.percent(10),
        x: 0,
        marginTop: 0,
        paddingTop: 0,
        height: am5.percent(100),
        width: am5.percent(100),
      }),
    );

    const textColors: TextColors = this._themeService.themeTextColors;

    legend.labels.template.setAll({
      fill: am5.color(textColors.primaryText),
      fontSize: 12,
    });

    legend.valueLabels.template.setAll({
      fill: am5.color(textColors.primaryText),
      fontSize: 12,
    });

    legend.markers.template.setAll({
      width: 12,   // Set the width of the bullets
      height: 12,   // Set the height of the bullets
    });

    legend.itemContainers.template.events.disableType('pointerover');  // Disable hover effect
    legend.itemContainers.template.events.disableType('click');  // Disable click events

    // Bind legend data and display only three legend items
    const dataItems = this.numberOfVisibleLegendItems
      ? this.series.dataItems.slice(0, this.numberOfVisibleLegendItems)
      : this.series.dataItems;
    legend.data.setAll(dataItems);
  }

  /**
   * Creates a formatted legend in the tooltip.
   *
   * @param {DefaultPieChartData[]} chartData - The chart data.
   * @returns {string | null} The formatted legend tooltip.
   */
  public getLegendTooltip(chartData: DefaultPieChartData[]): string | null {
    if (!this.hasTooltip) return null;
    const sum: number = chartData.reduce((acc, item) => acc + item.value, 0);
    if (!chartData.filter(item => item.color).length) return '';
    let tooltip: string = '<div style="font-size: 13px; overflow: hidden;">';
    chartData
      .filter(item => item.color)
      .forEach(item => {
        const percentValue: number = Math.round((item.value / sum) * 100);
        tooltip +=
          '<div class="secondary-border-color flexbox align-center larger-gap justify-spaced border-bottom-without-last-child" style="margin-bottom: 6px; padding-bottom: 6px;">'
          + '<div class="flexbox align-center xs-gap" style="width: 100%; margin-right: .5rem;">'
          + `<div style="background: ${item.color!.toCSSHex()}; width: 13px; height: 13px; border-radius: 3px">&nbsp;</div>`
          + `<div>${item.category}</div>`
          + `<div><strong> (${percentValue}%)</strong>:</div>`
          + '</div>'
          + '<div style="white-space: nowrap; text-align: right">'
          + `<strong>${item.value}</strong>`
          + ` von <strong>${sum}</strong> `
          + '</div>'
          + '</div>';
      });
    tooltip += '</div>';
    return tooltip;
  }

  /**
   * Assigns specific colors to the slices of the pie chart based on the data context.
   * If a color is defined in the data context, it will be used; otherwise, the default fill or stroke color will be applied.
   */
  private assignSliceSpecificColors() {
    if (!this.series) return;

    // Assign fill color based on dataContext
    this.series.slices.template.adapters.add("fill", (fill, target) => {
      return getColorFromAmPieChartDataContext(target) || fill; // Use the color from dataContext or default fill
    });

    // Assign stroke color based on dataContext
    this.series.slices.template.adapters.add("stroke", (stroke, target) => {
      return getColorFromAmPieChartDataContext(target) || stroke; // Use the color from dataContext or default stroke
    });
  }

}
